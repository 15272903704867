import React from "react";
import styles from "./Top.module.css";
import img1 from "../../../assets/aboutUs/img1.png";

const Top = () => {
  return (
    <div className="container overflow-hidden">
      <p data-aos="slide-right" className={styles.heading}>
        Welcome to VYNX
      </p>
      <p data-aos="slide-left" className={styles.subHeading}>
        Your Partner in Business Formation and Growth
      </p>
      <p data-aos="fade-up" data-aos-offset="80" className={styles.content}>
        At VYNX, we specialize in providing comprehensive, personalized business
        setup solutions for clients looking to establish companies in the UAE.
        We recognize that every business has unique needs, and our dedicated
        team is here to ensure a seamless journey from initial setup to ongoing
        support. Our expertise spans corporate structuring, and navigating the
        entire process of business formation in the UAE.
      </p>

      <div className={`row ${styles.contain} flex-column-reverse flex-md-row`}>
        <div data-aos="slide-right" className="col-md-6">
          <p className={styles.about}>About VYNX</p>
          <p className={styles.creativity}>
            Business Setup Solutions Tailored to Your Needs
          </p>
          <p className={styles.contn}>
            At VYNX, we provide end-to-end guidance in establishing your
            company, from corporate structuring and regulatory compliance to
            ongoing support. Our dedicated team works with you every step of the
            way, ensuring that you receive the best advice and solutions
            tailored to your business goals. With expertise in corporate
            structure, and company formation processes, we offer a smooth and
            efficient path to establishing your presence in the UAE.
          </p>
        </div>
        <div
          data-aos="slide-left"
          data-aos-offset="50"
          className={`col-md-6 ${styles.imgContain}`}
        >
          <img src={img1} alt="Corporate Structure" className={styles.img1} />
        </div>
      </div>
    </div>
  );
};

export default Top;
