import React, { useState } from "react";
import styles from "./Offshore.module.css";

const Offshore = () => {
  const [selectedLicense, setSelectedLicense] = useState(null);

  const openPopup = (license) => {
    setSelectedLicense(license);
  };

  const closePopup = () => {
    setSelectedLicense(null);
  };

  return (
    <div className={`container ${styles.contain} overflow-hidden`}>
      {/* Local Sponsor + Popular Licenses */}
      <section className={styles.Section}>
        <div className={styles.section1}>
          <h2 className={styles.heading1}>
          OFFSHORE COMPANIES IN THE UAE
          </h2>
          <p className={styles.heading_content}>
            Offshore companies under free zones like JAFZA and RAKICC are
            permitted to own real estate in the UAE.
          </p>
          <p>
            <strong className={styles.heading1}>Advantages:</strong>
            <ul className={styles.heading_content}>
              <li>Low startup cost</li>
              <li>Speedy incorporation process</li>
              <li>100% tax-free</li>
              <li>100% foreign ownership permitted</li>
              <li>No paid-up share capital or audit requirement</li>
              <li>Ability to open multiple bank accounts</li>
            </ul>
          </p>
        </div>

        <div className={styles.section2}>
          <h2 className={styles.heading2}>Popular Licenses</h2>
          <div className={styles.licenseList}>
            <div
              className={styles.licenseItem}
              onClick={() => openPopup("CryptoTrading")}
            >
              <h3>Crypto Trading License</h3>
            </div>
            <div
              className={styles.licenseItem}
              onClick={() => openPopup("GeneralTrading")}
            >
              <h3>General Trading License</h3>
            </div>
            <div
              className={styles.licenseItem}
              onClick={() => openPopup("E-Commerce")}
            >
              <h3>E-Commerce Trade License</h3>
            </div>
            <div
              className={styles.licenseItem}
              onClick={() => openPopup("ManagementConsultancy")}
            >
              <h3>Management Consultancy</h3>
            </div>
            <div
              className={styles.licenseItem}
              onClick={() => openPopup("RealEstate")}
            >
              <h3>Real Estate</h3>
            </div>
            <div
              className={styles.licenseItem}
              onClick={() => openPopup("DigitalMarketing")}
            >
              <h3>Digital Marketing</h3>
            </div>
          </div>

          {/* Popup Modal */}
          {selectedLicense && (
            <div className={styles.popupOverlay} onClick={closePopup}>
              <div
                className={styles.popupContent}
                onClick={(e) => e.stopPropagation()}
              >
                <h3>{selectedLicense}</h3>
                <p>
                  {selectedLicense === "CryptoTrading" &&
                    "Ready to dive into the world of cryptocurrency trading in Dubai? At Decisive Zone, we provide the necessary setup."}
                  {selectedLicense === "GeneralTrading" &&
                    "A General Trading License in Dubai allows businesses to engage in a wide range of commercial activities."}
                  {selectedLicense === "E-Commerce" &&
                    "An e-commerce trade license in the UAE legitimizes online businesses, ensuring compliance with local regulations."}
                  {selectedLicense === "ManagementConsultancy" &&
                    "A Management Consultancy License in Dubai permits firms to offer professional advisory services."}
                  {selectedLicense === "RealEstate" &&
                    "The real estate market in the UAE is booming with more people shifting their base to the country."}
                  {selectedLicense === "DigitalMarketing" &&
                    "Now is a great time to start a digital marketing business in the UAE due to the surge in demand for online services."}
                </p>
                <button
                  className={styles.closePopupButton}
                  onClick={closePopup}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </section>

      {/*License Types and Activities */}
      <section className={styles.licenseSection}>
        <div className={styles.stepsContainer}>
          <h2 className={styles.heading}>
            Key Features of an Offshore Company in the UAE
          </h2>
          <ul className={styles.stepsList}>
            <li>
              <strong>Shareholders:</strong> Full foreign ownership permitted.
            </li>
            <li>
              <strong>Directors:</strong> Flexible director requirements.
            </li>
            <li>
              <strong>Secretary:</strong> Optional company secretary.
            </li>
            <li>
              <strong>Share Capital:</strong> No minimum share capital required.
            </li>
            <li>
              <strong>Annual Reporting:</strong> No annual reporting or auditing
              required.
            </li>
          </ul>
        </div>

        <div className={styles.stepsContainer}>
          <h2 className={styles.heading}>
            Easy Steps to Get Your Offshore License
          </h2>
          <ul className={styles.stepsList}>
            <li>Choose business activity</li>
            <li>Finalize company name</li>
            <li>Complete incorporation paperwork</li>
            <li>License issuance</li>
            <li>Open company bank account</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Offshore;
