import React from "react";
import Footer from "../../components/Footer";
import BusinessBanner from '../../components/BusinessBanner';
import bg from '../../assets/207510.jpg';
import BusinessSetup from "../../components/BusinessSetup";

const business = () => {
  return (
    <>
      {/* Banner Section  */}
      <section>
        <BusinessBanner img={bg}/>
      </section>

      {/* Business Section  */}
      <section>
        <BusinessSetup />
      </section>

      {/* Footer Section  */}
      <section>
        <Footer />
      </section>
    </>
  );
};

export default business;