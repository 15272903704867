import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { freeZoneData } from "./data";
import styles from "./Individualfreezone.module.css";
import Slider from "react-slick";
import { Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const IndividualFreeZone = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [zoneData, setZoneData] = useState(null);

  useEffect(() => {
    const data = freeZoneData.find((zone) => zone.id === parseInt(id));
    setZoneData(data);
  }, [id]);

  if (!zoneData) {
    return <div>Loading...</div>;
  }

  // Client Reviews data
  const clientReviews = [
    {
      name: "John Doe",
      review: "Great business opportunities and seamless process!",
    },
    {
      name: "Jane Smith",
      review: "The best Free Zone for tech startups!",
    },
    {
      name: "Alice Johnson",
      review: "Exceptional support for company formation in the UAE.",
    },
    {
      name: "Michael Brown",
      review: "A one-stop solution for all my business needs.",
    },
    {
      name: "Sophia Williams",
      review: "Quick and hassle-free documentation process.",
    },
    {
      name: "David Wilson",
      review: "Outstanding guidance throughout the company setup journey.",
    },
    {
      name: "Emma Davis",
      review: "Professional and knowledgeable team!",
    },
    {
      name: "Liam Garcia",
      review: "Highly recommend their services for global entrepreneurs.",
    },
    {
      name: "Olivia Martinez",
      review: "Reliable, efficient, and trustworthy service providers.",
    },
    {
      name: "Noah Robinson",
      review: "They made my business vision come true!",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const toggleStep = (index) => {
    const stepContent = document.getElementById(`step-${index}`);
    const toggleIcon =
      stepContent.previousElementSibling.querySelector(".toggleIcon");
    if (stepContent.style.display === "none") {
      stepContent.style.display = "block";
      toggleIcon.textContent = "-";
    } else {
      stepContent.style.display = "none";
      toggleIcon.textContent = "+";
    }
  };

  return (
    <div className={styles.freeZonePage}>
      {/* Banner Section */}
      <div
        className={styles.imgbox}
        style={{ backgroundImage: `url(${zoneData.bgrimg})` }}
      >
        <div className={styles.overlay}>
          <div className="container">
            {zoneData.title && (
              <h1 className={styles.title}> {zoneData.title} </h1>
            )}
            <div className="row align-items-center">
              {/* Left Side Content */}
              <div className="col-md-6">
                <h2 className={styles.head}>{zoneData.bannertext}</h2>
                {zoneData.description && (
                  <p className={styles.description}>{zoneData.description}</p>
                )}
              </div>

              {/* Right Side Form */}
              <div className="col-md-6">
                <div className={styles.formContainer}>
                  <h2>Let's Get Started</h2>
                  {/* Form content */}
                  <form>
                    <div className={styles.formGroup}>
                      <div className="row">
                        <div className="col-6">
                          <input
                            type="text"
                            placeholder="First Name"
                            required
                            className={styles.inputField}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            type="text"
                            placeholder="Last Name"
                            required
                            className={styles.inputField}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.formGroup}>
                      <input
                        type="tel"
                        placeholder="Phone Number"
                        required
                        className={styles.inputField}
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <select required className={styles.inputField}>
                        <option value="">Select your nationality</option>
                        <option value="Afghan">Afghan</option>
                        <option value="Albanian">Albanian</option>
                        <option value="Algerian">Algerian</option>
                        <option value="American">American</option>
                        <option value="Andorran">Andorran</option>
                        <option value="Angolan">Angolan</option>
                        <option value="Antiguans">Antiguans</option>
                        <option value="Argentine">Argentine</option>
                        <option value="Armenian">Armenian</option>
                        <option value="Australian">Australian</option>
                        <option value="Austrian">Austrian</option>
                        <option value="Azerbaijani">Azerbaijani</option>
                        <option value="Bahamian">Bahamian</option>
                        <option value="Bahraini">Bahraini</option>
                        <option value="Bangladeshi">Bangladeshi</option>
                        <option value="Barbadian">Barbadian</option>
                        <option value="Barbadian">Barbadian</option>
                        <option value="Belarusian">Belarusian</option>
                        <option value="Belgian">Belgian</option>
                        <option value="Belizean">Belizean</option>
                        <option value="Beninese">Beninese</option>
                        <option value="Bhutanese">Bhutanese</option>
                        <option value="Bolivian">Bolivian</option>
                        <option value="Bosnian">Bosnian</option>
                        <option value="Botswanan">Botswanan</option>
                        <option value="Brazilian">Brazilian</option>
                        <option value="British">British</option>
                        <option value="Bruneian">Bruneian</option>
                        <option value="Bulgarian">Bulgarian</option>
                        <option value="Burkinese">Burkinese</option>
                        <option value="Burmese">Burmese</option>
                        <option value="Burundian">Burundian</option>
                        <option value="Cambodian">Cambodian</option>
                        <option value="Cameroonian">Cameroonian</option>
                        <option value="Canadian">Canadian</option>
                        <option value="Cape Verdean">Cape Verdean</option>
                        <option value="Central African">Central African</option>
                        <option value="Chadian">Chadian</option>
                        <option value="Chilean">Chilean</option>
                        <option value="Chinese">Chinese</option>
                        <option value="Colombian">Colombian</option>
                        <option value="Comorian">Comorian</option>
                        <option value="Congolese">Congolese</option>
                        <option value="Costa Rican">Costa Rican</option>
                        <option value="Croatian">Croatian</option>
                        <option value="Cuban">Cuban</option>
                        <option value="Cypriot">Cypriot</option>
                        <option value="Czech">Czech</option>
                        <option value="Danish">Danish</option>
                        <option value="Djiboutian">Djiboutian</option>
                        <option value="Dominican">Dominican</option>
                        <option value="Dutch">Dutch</option>
                        <option value="East Timorese">East Timorese</option>
                        <option value="Ecuadorean">Ecuadorean</option>
                        <option value="Egyptian">Egyptian</option>
                        <option value="Emirati">Emirati</option>
                        <option value="Equatorial Guinean">
                          Equatorial Guinean
                        </option>
                        <option value="Eritrean">Eritrean</option>
                        <option value="Estonian">Estonian</option>
                        <option value="Ethiopian">Ethiopian</option>
                        <option value="Fijian">Fijian</option>
                        <option value="Finnish">Finnish</option>
                        <option value="French">French</option>
                        <option value="Gabonese">Gabonese</option>
                        <option value="Gambian">Gambian</option>
                        <option value="Georgian">Georgian</option>
                        <option value="German">German</option>
                        <option value="Ghanaian">Ghanaian</option>
                        <option value="Greek">Greek</option>
                        <option value="Grenadian">Grenadian</option>
                        <option value="Guatemalan">Guatemalan</option>
                        <option value="Guinea-Bissauan">Guinea-Bissauan</option>
                        <option value="Guinean">Guinean</option>
                        <option value="Guyanese">Guyanese</option>
                        <option value="Haitian">Haitian</option>
                        <option value="Honduran">Honduran</option>
                        <option value="Hungarian">Hungarian</option>
                        <option value="Icelander">Icelander</option>
                        <option value="Indian">Indian</option>
                        <option value="Indonesian">Indonesian</option>
                        <option value="Iranian">Iranian</option>
                        <option value="Iraqi">Iraqi</option>
                        <option value="Irish">Irish</option>
                        <option value="Israeli">Israeli</option>
                        <option value="Italian">Italian</option>
                        <option value="Ivorian">Ivorian</option>
                        <option value="Jamaican">Jamaican</option>
                        <option value="Japanese">Japanese</option>
                        <option value="Jordanian">Jordanian</option>
                        <option value="Kazakhstani">Kazakhstani</option>
                        <option value="Kenyan">Kenyan</option>
                        <option value="Kittitian and Nevisian">
                          Kittitian and Nevisian
                        </option>
                        <option value="Kuwaiti">Kuwaiti</option>
                        <option value="Kyrgyz">Kyrgyz</option>
                        <option value="Laotian">Laotian</option>
                        <option value="Latvian">Latvian</option>
                        <option value="Lebanese">Lebanese</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberian">Liberian</option>
                        <option value="Libyan">Libyan</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuanian">Lithuanian</option>
                        <option value="Luxembourgish">Luxembourgish</option>
                        <option value="Macedonian">Macedonian</option>
                        <option value="Malagasy">Malagasy</option>
                        <option value="Malawian">Malawian</option>
                        <option value="Malaysian">Malaysian</option>
                        <option value="Maldivian">Maldivian</option>
                        <option value="Malian">Malian</option>
                        <option value="Maltese">Maltese</option>
                        <option value="Marshallese">Marshallese</option>
                        <option value="Mauritian">Mauritian</option>
                        <option value="Mexican">Mexican</option>
                        <option value="Micronesian">Micronesian</option>
                        <option value="Moldovan">Moldovan</option>
                        <option value="Monacan">Monacan</option>
                        <option value="Mongolian">Mongolian</option>
                        <option value="Montenegrin">Montenegrin</option>
                        <option value="Moroccan">Moroccan</option>
                        <option value="Mozambican">Mozambican</option>
                        <option value="Namibian">Namibian</option>
                        <option value="Nauruan">Nauruan</option>
                        <option value="Nepalese">Nepalese</option>
                        <option value="New Zealander">New Zealander</option>
                        <option value="Nicaraguan">Nicaraguan</option>
                        <option value="Nigerian">Nigerian</option>
                        <option value="Nigerien">Nigerien</option>
                        <option value="North Korean">North Korean</option>
                        <option value="Northern Mariana Islander">
                          Northern Mariana Islander
                        </option>
                        <option value="Norwegian">Norwegian</option>
                        <option value="Omani">Omani</option>
                        <option value="Pakistani">Pakistani</option>
                        <option value="Palauan">Palauan</option>
                        <option value="Panamanian">Panamanian</option>
                        <option value="Papua New Guinean">
                          Papua New Guinean
                        </option>
                        <option value="Paraguayan">Paraguayan</option>
                        <option value="Peruvian">Peruvian</option>
                        <option value="Philippine">Philippine</option>
                        <option value="Polish">Polish</option>
                        <option value="Portuguese">Portuguese</option>
                        <option value="Qatari">Qatari</option>
                        <option value="Romanian">Romanian</option>
                        <option value="Russian">Russian</option>
                        <option value="Rwandan">Rwandan</option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint Lucian">Saint Lucian</option>
                        <option value="Saint Vincentian">
                          Saint Vincentian
                        </option>
                        <option value="Samoan">Samoan</option>
                        <option value="San Marinese">San Marinese</option>
                        <option value="Sao Tomean">Sao Tomean</option>
                        <option value="Saudi">Saudi</option>
                        <option value="Senegalese">Senegalese</option>
                        <option value="Serbian">Serbian</option>
                        <option value="Seychellois">Seychellois</option>
                        <option value="Sierra Leonean">Sierra Leonean</option>
                        <option value="Singaporean">Singaporean</option>
                        <option value="Slovak">Slovak</option>
                        <option value="Slovenian">Slovenian</option>
                        <option value="Solomon Islander">
                          Solomon Islander
                        </option>
                        <option value="Somali">Somali</option>
                        <option value="South African">South African</option>
                        <option value="South Korean">South Korean</option>
                        <option value="South Sudanese">South Sudanese</option>
                        <option value="Spanish">Spanish</option>
                        <option value="Sri Lankan">Sri Lankan</option>
                        <option value="Sudanese">Sudanese</option>
                        <option value="Surinamese">Surinamese</option>
                        <option value="Swazi">Swazi</option>
                        <option value="Swedish">Swedish</option>
                        <option value="Swiss">Swiss</option>
                        <option value="Syrian">Syrian</option>
                        <option value="Taiwanese">Taiwanese</option>
                        <option value="Tajik">Tajik</option>
                        <option value="Tanzanian">Tanzanian</option>
                        <option value="Thai">Thai</option>
                        <option value="Togolese">Togolese</option>
                        <option value="Tongan">Tongan</option>
                        <option value="Trinidadian or Tobagonian">
                          Trinidadian or Tobagonian
                        </option>
                        <option value="Tunisian">Tunisian</option>
                        <option value="Turkish">Turkish</option>
                        <option value="Turkmen">Turkmen</option>
                        <option value="Tuvaluan">Tuvaluan</option>
                        <option value="Ugandan">Ugandan</option>
                        <option value="Ukrainian">Ukrainian</option>
                        <option value="Uruguayan">Uruguayan</option>
                        <option value="Uzbekistani">Uzbekistani</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuelan">Venezuelan</option>
                        <option value="Vietnamese">Vietnamese</option>
                        <option value="Yemeni">Yemeni</option>
                        <option value="Zambian">Zambian</option>
                        <option value="Zimbabwean">Zimbabwean</option>
                      </select>
                    </div>
                    <div className={styles.formGroup}>
                      <textarea
                        placeholder="Message"
                        rows="3"
                        className={styles.inputField}
                      ></textarea>
                    </div>
                    <button className={styles.submitButton}>Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container>
        {/* About Us Section */}
        <section className={styles.about}>
          <div className={styles.content}>
            <p>
              Why choose {""}
              <span className={styles.abouttitle}>{zoneData.abouttitle} </span>
              <h4>{zoneData.name}</h4>
            </p>
            <h1 className={styles.abouttitle}>{zoneData.about}</h1>
          </div>
          {zoneData.aboutimg ? (
            <div className={styles.image}>
              <img src={zoneData.aboutimg} alt="About Us" />
            </div>
          ) : (
            <div className={styles.highlights}>
              {zoneData.highlights.map((highlight, index) => (
                <div key={index} className={styles.highlight}>
                  {highlight.title && <h3>* {highlight.title}</h3>}
                  <p>{highlight.content}</p>
                </div>
              ))}
            </div>
          )}
        </section>

        {/* Types of Business Licenses Section */}
        {zoneData.licenses && (
          <section className={styles.licenses}>
            <h1>{zoneData.head}</h1>
            <h2>{zoneData.head2}</h2>
            <h3>{zoneData.head3}</h3>
            <div className={styles.licensesGrid}>
              {zoneData.licenses.map((license, index) => (
                <div className={styles.licenseCard} key={index}>
                  <h4>{license.title}</h4>
                  <p>{license.content}</p>
                </div>
              ))}
            </div>
          </section>
        )}

        {/* Step-by-Step Process Section */}
        {zoneData.process && (
          <section className={styles.process}>
            <h1>{zoneData.stephead}</h1>
            <h2>{zoneData.stepdiscription}</h2>
            <ul className={styles.stepsList}>
              {zoneData.process.map((step, index) => (
                <li key={index} className={styles.stepItem}>
                  <div
                    className={styles.stepHeader}
                    onClick={() => toggleStep(index)}
                  >
                    <h4>{step.title}</h4>
                    <span className={styles.toggleIcon}>+</span>
                  </div>
                  <div
                    className={styles.stepContent}
                    id={`step-${index}`}
                    style={{ display: "none" }}
                  >
                    <p>{step.content}</p>
                  </div>
                </li>
              ))}
            </ul>
          </section>
        )}

        {/* Why Choose Us Section */}
        {zoneData.whyhead && (
          <section className={styles.whyChooseUs}>
            <h1>{zoneData.whyhead}</h1>
            <h2>{zoneData.whydiscription}</h2>
            <div className={styles.whyGrid}>
              {zoneData.whylist &&
                zoneData.whylist.map((why, index) => (
                  <div className={styles.whyCard} key={index}>
                    <h4>{why.title}</h4>
                    <p>{why.content}</p>
                  </div>
                ))}
            </div>
          </section>
        )}
        {/* Extra Section */}
        {zoneData.extra && (
          <section className={styles.extra}>
            <h1>{zoneData.extra}</h1>
            <h2>{zoneData.extracontent}</h2>
          </section>
        )}
        {/* Client Reviews Section */}
        <section className={styles.reviews}>
          <h2>What Our Clients Say</h2>
          <Slider {...settings}>
            {clientReviews.map((review, index) => (
              <div key={index}>
                <div className={styles.reviewContent}>
                  <strong>{review.name}</strong>
                  <p>"{review.review}"</p>
                </div>
              </div>
            ))}
          </Slider>
        </section>
      </Container>
    </div>
  );
};

export default IndividualFreeZone;
