// import bannerImg from '../../assets/projectDetails/banner.png';
// import cardImg from '../../assets/projectDetails/card.png';
// import projectImg1 from '../../assets/projectDetails/project1.png';
// import projectImg2 from '../../assets/projectDetails/project2.png';

const fakeData = [
  {
    title:
      "UAE Real Estate Market: The Rise of Sustainable and Eco-Friendly Developments",
    description:
      "The UAE's real estate market is increasingly focusing on sustainable and eco-friendly developments. With growing concerns about environmental impact and a push for green building standards, developers are prioritizing energy-efficient designs, renewable energy sources, and sustainable materials. This shift not only helps reduce the ecological footprint but also aligns with the UAE's long-term goals for environmental sustainability, attracting eco-conscious investors and residents.",
  },
  {
    title:
      "How the UAE is Becoming a Global Tech Hub for Startups and Innovators",
    description:
      "The UAE is rapidly transforming into a global tech hub, attracting startups and innovators from around the world. With favorable business regulations, investment in innovation, and a thriving ecosystem for technology entrepreneurs, the UAE offers a competitive edge for tech companies looking to expand. This blog explores the various initiatives, such as the Dubai Future Foundation and Abu Dhabi’s tech incubators, that make the UAE a prime destination for technology-driven ventures.",
  },
  {
    title:
      "How the UAE's Economic Diversification is Shaping Its Future Growth",
    description:
      "The UAE’s commitment to economic diversification is reshaping its future, moving away from oil dependency to foster growth in sectors such as technology, tourism, healthcare, and renewable energy. Initiatives like the National Innovation Strategy and Vision 2021 are playing a crucial role in this transformation. This blog examines how the UAE is positioning itself for sustainable growth by investing in innovation, infrastructure, and global partnerships.",
  },
  {
    title:
      "How the UAE is Strengthening Its Position as a Global Financial Center",
    description:
      "The UAE is enhancing its status as a global financial center by attracting international banks, financial institutions, and investors. With initiatives like the Dubai International Financial Centre (DIFC) and favorable regulations for foreign businesses, the UAE offers an ideal environment for financial growth. This blog dives into the factors driving the UAE’s financial sector expansion, including its tax-free zones, ease of doing business, and strategic location at the crossroads of East and West.",
  },
  {
    title:
      "The Impact of the UAE's Vision 2040 on the National Economy and Infrastructure",
    description:
      "UAE Vision 2040 sets a clear path for the nation’s long-term growth, focusing on economic diversification, infrastructure development, and sustainability. This ambitious plan aims to transform the UAE into a global leader in innovation and business. This blog explores how Vision 2040 will reshape key sectors such as transportation, real estate, energy, and healthcare, positioning the UAE as a competitive and sustainable economy in the coming decades.",
  },

  {
    category: "Frequently Asked Questions (FAQs)",
    questions: [
      {
        question: "What is the process of setting up a business in the UAE?",
        answer:
          "The process typically involves choosing a business structure, registering with the appropriate authorities, obtaining a license, and fulfilling any visa requirements.",
      },
      {
        question: "How long does it take to form a company in the UAE?",
        answer:
          "The time required to form a company in the UAE varies, but the process typically takes between 1-3 weeks, depending on the type of business and the complexity of the setup.",
      },
      {
        question: "Do I need a local sponsor for my business in the UAE?",
        answer:
          "In some cases, a local sponsor may be required, especially for certain business activities in mainland UAE. However, in free zones, foreign ownership is typically allowed.",
      },
      {
        question:
          "What are the costs involved in setting up a business in the UAE?",
        answer:
          "The cost of setting up a business in the UAE varies based on the business type, location, and other factors such as licensing, office space, and visa costs. On average, it can range from a few thousand to tens of thousands of dollars.",
      },
      {
        question: "What types of business licenses are available in the UAE?",
        answer:
          "There are several types of business licenses in the UAE, including commercial, industrial, professional, and tourism licenses, depending on the nature of your business.",
      },
      {
        question: "Can I apply for a business license remotely?",
        answer:
          "Yes, many free zones in the UAE offer online services that allow entrepreneurs to apply for a business license remotely without the need to visit the UAE in person.",
      },
    ],
  },
];

export default fakeData;
