import React from "react";
import Footer from "../../components/Footer";
import OffshoreBanner from '../../components/OffshoreBanner';
import bg from '../../assets/4265917.jpg';  
import Offshore from "../../components/Offshore";

const offshore = () => {
  return (
    <>
      {/* Banner Section  */}
      <section>
        <OffshoreBanner img={bg}/>
      </section>

      {/* Business Section  */}
      <section>
        <Offshore />
      </section>

      {/* Footer Section  */}
      <section>
        <Footer />
      </section>
    </>
  );
};

export default offshore;