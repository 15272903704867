import React, { useState, useEffect } from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styles from "./NavbarMain.module.css";

const NavbarMain = () => {
  const [showDropdown, setShowDropdown] = useState({
    businessSetup: false,
  });

  const [showSubDropdown, setShowSubDropdown] = useState(false);

  const [activeNav, setActiveNav] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);

  const closeNav = () => setShowSubDropdown(false);

  const handleActiveNav = (i) => {
    const newActiveNav = activeNav.map((_, index) => index === i);
    setActiveNav(newActiveNav);
    sessionStorage.setItem("NavbarMain", JSON.stringify(newActiveNav));
  };

  const handleDropdown = (section) => {
    setShowDropdown((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  useEffect(() => {
    const storedNavState = sessionStorage.getItem("NavbarMain");
    if (storedNavState) {
      setActiveNav(JSON.parse(storedNavState));
    }
  }, []);

  return (
    <Navbar
      style={{
        backgroundColor: "white",
        boxShadow: "1px 1px 10px rgb(0 0 0 / 0.4)",
      }}
      variant="light"
      expand="lg"
      sticky="top"
      onToggle={() => setShowDropdown((prev) => !prev)}
    >
      <Container>
        <div className={styles.logoContainer}>
          <a href="https://vynxgroup.com/">
            <img
              src={require("../../assets/Company logo.jpg")}
              alt="VYNX Logo"
              className={styles.logoImage}
            />
          </a>
          <Navbar.Brand href="https://vynxgroup.com/" className={styles.logo}>
            VYNX
            <div className={styles.subLogo}> International PVT.LTD</div>
          </Navbar.Brand>
        </div>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={`${styles.navLinks} mx-auto`}>
            <NavLink
              to="/"
              className={`${styles.nav_text} nav-link ${
                activeNav[0] ? styles.active : ""
              }`}
              style={{ marginTop: "18px", textDecoration: "none" }}
              onClick={() => {
                handleActiveNav(0);
                closeNav();
              }}
            >
              Home
            </NavLink>

            {/* Business Setup Dropdown */}
            <NavDropdown
              show={showDropdown.businessSetup}
              onMouseEnter={() => handleDropdown("businessSetup")}
              onMouseLeave={() => handleDropdown("businessSetup")}
              className={`nav-link ${styles.drop}`}
              title={
                <span
                  className={styles.dropicon}
                  onClick={() => {
                    handleActiveNav(1);
                    closeNav();
                  }}
                >
                  <span
                    className={`${styles.nav_text} my-auto ${
                      activeNav[1] ? styles.active : ""
                    }`}
                  >
                    Business Setup
                  </span>
                  <i className="fas fa-angle-down"></i>
                </span>
              }
              id="business-setup-dropdown"
            >
              {/* Mainland Option */}
              <NavDropdown.Item className={styles.dropdownItem}>
                <NavLink to="/businessSetup" className={styles.dropdownText}>
                  Mainland
                </NavLink>
              </NavDropdown.Item>

              {/* Free Zone Option with Submenu */}
              <NavDropdown.Item
                className={styles.dropdownItem}
                onMouseEnter={() => setShowSubDropdown(true)}
                onMouseLeave={() => setShowSubDropdown(false)}
              >
                <span className={styles.dropdownText}>
                  Free Zone
                  <i
                    className="fas fa-angle-right"
                    style={{ marginLeft: "5px" }}
                  ></i>
                </span>

                {showSubDropdown && (
                  <div className={styles.subDropdown}>
                    {/* Free Zone Sub-options */}
                    <NavDropdown.Item>
                      <NavLink
                        to="/individualFreeZone?id=1"
                        className={styles.dropdownText}
                      >
                        Abu Dhabi Global Market Free Zone (ADGM)
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        to="/individualFreeZone?id=2"
                        className={styles.dropdownText}
                      >
                        Dubai Airport Freezone (DAFZA)
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        to="/individualFreeZone?id=3"
                        className={styles.dropdownText}
                      >
                        Dubai Multi Commodities Center (DMCC)
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        to="/individualFreeZone?id=4"
                        className={styles.dropdownText}
                      >
                        Dubai Silicon Oasis Authority (DSOA)
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        to="/individualFreeZone?id=5"
                        className={styles.dropdownText}
                      >
                        Fujairah Free Zone Authority (FFZA)
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        to="/individualFreeZone?id=6"
                        className={styles.dropdownText}
                      >
                        International Free Zone Authority (IFZA)
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        to="/individualFreeZone?id=7"
                        className={styles.dropdownText}
                      >
                        Jebel Ali Free Zone (JAFZA)
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        to="/individualFreeZone?id=8"
                        className={styles.dropdownText}
                      >
                        Khalifa Industrial Zone Abu Dhabi (KIZAD)
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        to="/individualFreeZone?id=9"
                        className={styles.dropdownText}
                      >
                        Masdar City Free Zone
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        to="/individualFreeZone?id=10"
                        className={styles.dropdownText}
                      >
                        Maydan Free Zone (MFZ)
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        to="/individualFreeZone?id=11"
                        className={styles.dropdownText}
                      >
                        Ras Al Khaimah Economic Zone (RAKEZ)
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        to="/individualFreeZone?id=12"
                        className={styles.dropdownText}
                      >
                        Sharjah Media City Free Zone (SHAMS)
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink
                        to="/individualFreeZone?id=13"
                        className={styles.dropdownText}
                      >
                        Sharjah Publishing City Free Zone (SPC)
                      </NavLink>
                    </NavDropdown.Item>
                  </div>
                )}
              </NavDropdown.Item>

              {/* Offshore Option */}
              <NavDropdown.Item className={styles.dropdownItem}>
                <NavLink to="/offshore" className={styles.dropdownText}>
                  Offshore
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              show={showDropdown.services}
              onMouseEnter={() => handleDropdown("services")}
              onMouseLeave={() => handleDropdown("services")}
              className={`nav-link ${styles.drop}`}
              title={
                <NavLink
                  to="/services"
                  style={{ textDecoration: "none" }}
                  className={styles.dropicon}
                  onClick={() => {
                    handleActiveNav(3);
                    closeNav();
                  }}
                >
                  <span
                    className={`${styles.nav_text} my-auto ${
                      activeNav[3] ? styles.active : ""
                    }`}
                  >
                    Services
                  </span>
                  <i className="fas fa-angle-down"></i>
                </NavLink>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item className={styles.dropdownItem}>
                <NavLink
                  to="/individualService?id=1"
                  onClick={() => {
                    handleActiveNav(3);
                    closeNav();
                  }}
                  className={styles.dropdownText}
                  style={{ textDecoration: "none" }}
                >
                  All License Services
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropdownItem}>
                <NavLink
                  to="/individualService?id=4"
                  onClick={() => {
                    handleActiveNav(3);
                    closeNav();
                  }}
                  className={styles.dropdownText}
                  style={{ textDecoration: "none" }}
                >
                  Citizenship Program
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropdownItem}>
                <NavLink
                  to="/individualService?id=6"
                  onClick={() => {
                    handleActiveNav(3);
                    closeNav();
                  }}
                  className={styles.dropdownText}
                  style={{ textDecoration: "none" }}
                >
                  Customs & Product Registration
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropdownItem}>
                <NavLink
                  to="/individualService?id=3"
                  onClick={() => {
                    handleActiveNav(3);
                    closeNav();
                  }}
                  className={styles.dropdownText}
                  style={{ textDecoration: "none" }}
                >
                  Finance and Banking
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropdownItem}>
                <NavLink
                  to="/individualService?id=5"
                  onClick={() => {
                    handleActiveNav(3);
                    closeNav();
                  }}
                  className={styles.dropdownText}
                  style={{ textDecoration: "none" }}
                >
                  VIP Medical Test
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropdownItem}>
                <NavLink
                  to="/individualService?id=2"
                  onClick={() => {
                    handleActiveNav(3);
                    closeNav();
                  }}
                  className={styles.dropdownText}
                  style={{ textDecoration: "none" }}
                >
                  Visa Services
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>

            <NavLink
              to="/projectDetails"
              className={`${styles.nav_text} nav-link ${
                activeNav[4] ? styles.active : ""
              }`}
              style={{ marginTop: "18px" }}
              onClick={() => {
                handleActiveNav(4);
                closeNav();
              }}
            >
              <span
                className={`${styles.nav_text} my-auto ${
                  activeNav[4] ? styles.active : ""
                }`}
              >
                Resources
              </span>
            </NavLink>

            <NavLink
              to="/aboutUs"
              className={`${styles.nav_text} nav-link ${
                activeNav[2] ? styles.active : ""
              }`}
              style={{ marginTop: "18px" }}
              onClick={() => {
                handleActiveNav(2);
                closeNav();
              }}
            >
              About us
            </NavLink>

            <NavLink
              to="/contactUs"
              className={`${styles.nav_text} nav-link ${
                activeNav[5] ? styles.active : ""
              }`}
              style={{ marginTop: "18px" }}
              onClick={() => {
                handleActiveNav(5);
                closeNav();
              }}
            >
              Contact Us
            </NavLink>
          </Nav>

          <NavLink
            to="/costcaliculation"
            className={`${styles.nav_text} ${styles.costCalculation} nav-link ${
              activeNav[6] ? styles.active : ""
            }`}
            onClick={() => {
              handleActiveNav(6);
              closeNav();
            }}
          >
            Cost Calculation
          </NavLink>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarMain;
