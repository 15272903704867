import React, { useState, useEffect } from "react";
import styles from "./Banner.module.css";

import Bgr1 from "../../assets/3191609.jpg";
import Bgr from "../../assets/222677.jpg";
import Bgr2 from "../../assets/business0.avif";
import Bgr3 from "../../assets/1393198.jpg";
import Bgr4 from "../../assets/business4.jpg";
import bgr5 from "../../assets/bgr.jpg";

const Banner = () => {
  const images = [Bgr, Bgr1, Bgr2, Bgr3, Bgr4, bgr5];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div
      className={styles.imgbox}
      style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
    >
      <div className={styles.center}>
        <h1 className={styles.text}>Vynx Business Formation</h1>
        <p className={styles.subText}>
          Your one-stop solution for company formation
        </p>
        {/* <p className={styles.description}>
          VYNX GROUP OF COMPANIES helps businesses launch and expand across the
          globe with expertise in IT, Telecom, Education, and Company Formation
          services.
        </p> */}
      </div>
      <span
        aria-hidden="true"
        className={`${styles.carouselControl} ${styles["carousel-control-prev"]}`}
        onClick={prevImage}
      >
        &#10094;
      </span>
      <span
        aria-hidden="true"
        className={`${styles.carouselControl} ${styles["carousel-control-next"]}`}
        onClick={nextImage}
      >
        &#10095;
      </span>
    </div>
  );
};

export default Banner;
