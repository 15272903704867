import React from "react";
import ProposalPage from "../../components/ProposalPage";

const proposalPage = () => {
  return (
    <>
      <section>
        <ProposalPage />
      </section>
    </>
  );
};

export default proposalPage;
