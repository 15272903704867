import React, { useState } from "react";
import styles from "./BusinessSetup.module.css";

const BusinessSetup = () => {
  const [selectedLicense, setSelectedLicense] = useState(null);

  const openPopup = (license) => {
    setSelectedLicense(license);
  };

  const closePopup = () => {
    setSelectedLicense(null);
  };

  return (
    <div className={`container ${styles.contain} overflow-hidden`}>
      {/* Local Sponsor + Popular Licenses */}
      <section className={styles.Section}>
        <div className={styles.section1}>
          <h2 className={styles.heading1}>
            No Local Sponsor Needed for Dubai Mainland License
          </h2>
          <p className={styles.heading_content}>
            A local sponsor is no longer needed to get a Dubai mainland license
            for around 1000 commercial and industrial activities.
          </p>
          <p>
            <strong className={styles.heading1}>Advantages:</strong>
            <ul className={styles.heading_content}>
              <li>Freedom to rent commercial space in any area</li>
              <li>Ability to conduct a wide range of business activities</li>
              <li>Option to trade anywhere in the UAE and internationally</li>
              <li>100% foreign ownership</li>
              <li>No currency restriction</li>
              <li>Easy employment visa processing</li>
              <li>Advantage of bidding on government projects</li>
              <li>Ability to open a corporate bank account easily</li>
            </ul>
          </p>
        </div>

        <div className={styles.section2}>
          <h2 className={styles.heading2}>Popular Licenses</h2>
          <div className={styles.licenseList}>
            <div
              className={styles.licenseItem}
              onClick={() => openPopup("CryptoTrading")}
            >
              <h3>Crypto Trading License</h3>
            </div>
            <div
              className={styles.licenseItem}
              onClick={() => openPopup("GeneralTrading")}
            >
              <h3>General Trading License</h3>
            </div>
            <div
              className={styles.licenseItem}
              onClick={() => openPopup("E-Commerce")}
            >
              <h3>E-Commerce Trade License</h3>
            </div>
            <div
              className={styles.licenseItem}
              onClick={() => openPopup("ManagementConsultancy")}
            >
              <h3>Management Consultancy</h3>
            </div>
            <div
              className={styles.licenseItem}
              onClick={() => openPopup("RealEstate")}
            >
              <h3>Real Estate</h3>
            </div>
            <div
              className={styles.licenseItem}
              onClick={() => openPopup("DigitalMarketing")}
            >
              <h3>Digital Marketing</h3>
            </div>
          </div>

          {/* Popup Modal */}
          {selectedLicense && (
            <div className={styles.popupOverlay} onClick={closePopup}>
              <div
                className={styles.popupContent}
                onClick={(e) => e.stopPropagation()}
              >
                <h3>{selectedLicense}</h3>
                <p>
                  {selectedLicense === "CryptoTrading" &&
                    "Ready to dive into the world of cryptocurrency trading in Dubai? At Decisive Zone, we provide the necessary setup."}
                  {selectedLicense === "GeneralTrading" &&
                    "A General Trading License in Dubai allows businesses to engage in a wide range of commercial activities."}
                  {selectedLicense === "E-Commerce" &&
                    "An e-commerce trade license in the UAE legitimizes online businesses, ensuring compliance with local regulations."}
                  {selectedLicense === "ManagementConsultancy" &&
                    "A Management Consultancy License in Dubai permits firms to offer professional advisory services."}
                  {selectedLicense === "RealEstate" &&
                    "The real estate market in the UAE is booming with more people shifting their base to the country."}
                  {selectedLicense === "DigitalMarketing" &&
                    "Now is a great time to start a digital marketing business in the UAE due to the surge in demand for online services."}
                </p>
                <button
                  className={styles.closePopupButton}
                  onClick={closePopup}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </section>

      {/*License Types and Activities */}
      <section className={styles.licenseSection}>
        <div className={styles.stepsContainer}>
          <h2 className={styles.heading}>
            Easy Steps to Get Your Mainland License in Dubai
          </h2>
          <ul className={styles.stepsList}>
            <li>Choose business activity</li>
            <li>Choose company structure</li>
            <li>Get trade name approval</li>
            <li>Get initial license approval</li>
            <li>Get external approval (if required)</li>
            <li>License issuance</li>
            <li>Open company bank account</li>
            <li>Get establishment card</li>
            <li>Labour file</li>
            <li>Process visa</li>
          </ul>
        </div>
        <div className={styles.licenseDetails}>
          <h2 className={styles.heading}>License Types and Activities</h2>
          <p className={styles.subHeading}>
            Setting up a Mainland company in the UAE unlocks access to over
            3,000 business activities. The type of license and specific
            requirements depend on your chosen business activity, company
            structure, and operational jurisdiction.
          </p>
          <h3 className={styles.heading}>License Types</h3>
          <ul className={styles.licenseList}>
            <li>
              <strong>Commercial License:</strong> Required for businesses
              involved in trading and supplying goods.
            </li>
            <li>
              <strong>Industrial License:</strong> Required for all
              manufacturing and industrial activities in the UAE, including
              fabrication and production.
            </li>
            <li>
              <strong>Professional License:</strong> Issued to individuals or
              companies offering services, requiring a local service
              agent for certain operations.
            </li>
            <li>
              <strong>Tourism License:</strong> Required for companies and
              establishments operating in the travel and tourism sector in Dubai
              and the UAE.
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default BusinessSetup;
