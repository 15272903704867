import Home from "../views/home";
import Service from "../views/services";
import ContactUs from "../views/contactUs";
import IndividualService from "../views/individualService";
import AboutUs from "../views/aboutUs";
import ProjectDetails from "../views/projectDetails";
import BusinessSetup from "../views/business";
import Offshore from "../views/offshore";
import Individualfreezone from "../views/individualfreezone";
import Costcaliculation from "../views/ccaliculation";
import ProposalPage from "../views/proposalPage";

const routes = [
  {
    components: <Home />,
    to: "/",
  },
  {
    components: <Service />,
    to: "/services",
  },
  {
    components: <ContactUs />,
    to: "/contactUs",
  },
  {
    components: <IndividualService />,
    to: "/individualService",
  },
  {
    components: <AboutUs />,
    to: "/aboutUs",
  },
  {
    components: <ProjectDetails />,
    to: "/projectDetails",
  },

  {
    components: <BusinessSetup />,
    to: "/businessSetup",
  },

  {
    components: <Offshore />,
    to: "/offshore",
  },
  {
    components : <Individualfreezone />,
    to: "/individualfreezone",
  },
  {
    components : <Costcaliculation />,
    to: "/costcaliculation",
  },
  {
    components : <ProposalPage />,
    to: "/proposalPage",
  }
];

export default routes;
