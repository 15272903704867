import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import styles from "./IndividualService.module.css";
import { useLocation } from "react-router-dom";
import data from "./data";

const IndividualService = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [formContent, setFormContent] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    nationality: "",
    email: "",
    message: "",
  });
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const { search } = useLocation();
  const id = parseInt(new URLSearchParams(search).get("id"));
  let service = data.filter((x) => x.id === id)[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormContent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_y5gcq3m",
        "template_od23dip",
        {
          firstName: formContent.firstName,
          lastName: formContent.lastName,
          phoneNumber: formContent.phoneNumber,
          nationality: formContent.nationality,
          email: formContent.email,
          message: formContent.message,
        },
        "ccwxeaWFWlHzQyhqA"
      )
      .then(
        (response) => {
          console.log(
            "Email sent successfully!",
            response.status,
            response.text
          );
          setIsModalOpen(false);
          setShowSuccessPopup(true);
          setFormContent({
            firstName: "",
            lastName: "",
            phoneNumber: "",
            nationality: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          console.error("Error sending email:", error);
          alert("Failed to send email. Please check the console for details.");
        }
      );
  };

  const handleClosePopup = () => {
    setShowSuccessPopup(false);
  };

  return (
    <div className={`container ${styles.contain} overflow-hidden`}>
      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <button
              className={styles.closeIcon}
              onClick={() => setIsModalOpen(false)}
            >
              &times;
            </button>
            <h2 className={styles.modalTitle}>
              Get a Callback within 60 minutes
            </h2>
            <form onSubmit={handleSubmit}>
              <div
                className={styles.modalField}
                style={{ display: "flex", gap: "20px" }}
              >
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formContent.firstName}
                  onChange={handleChange}
                  required
                  className={styles.modalInput}
                  placeholder="First Name"
                />
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formContent.lastName}
                  onChange={handleChange}
                  required
                  className={styles.modalInput}
                  placeholder="Last Name"
                />
              </div>
              <div className={styles.modalField}>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formContent.phoneNumber}
                  onChange={handleChange}
                  pattern="^[0-9]{10}$"
                  required
                  className={styles.modalInput}
                  placeholder="Phone Number"
                />
              </div>
              <div className={styles.modalField}>
                <select
                  id="nationality"
                  name="nationality"
                  value={formContent.nationality}
                  onChange={handleChange}
                  required
                  className={styles.modalInput}
                >
                  <option value="" disabled>
                    Select Nationality
                  </option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Antigua &amp; Deps">Antigua &amp; Deps</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia Herzegovina">Bosnia Herzegovina</option>
                  <option value="Botswana">Botswana</option>
                  <option value="Brazil">Brazil</option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina">Burkina</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Central African Rep">
                    Central African Rep
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Congo {Democratic Rep}">
                    Congo Democratic Rep
                  </option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="CzechRepublic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="East Timor">East Timor</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Greece">Greece</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-Bissau">Guinea-Bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran">Iran</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland Republic">Ireland Republic</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Ivory Coast">Ivory Coast</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea North">Korea North</option>
                  <option value="Korea South">Korea South</option>
                  <option value="Kosovo">Kosovo</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Laos">Laos</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libya">Libya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macedonia">Macedonia</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia">Micronesia</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestine">Palestine</option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Romania">Romania</option>
                  <option value="Russian Federation">Russian Federation</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="St Kitts &amp; Nevis">
                    St Kitts &amp; Nevis
                  </option>
                  <option value="St Lucia">St Lucia</option>
                  <option value="Saint Vincent &amp; the Grenadines">
                    Saint Vincent &amp; the Grenadines
                  </option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome &amp; Principe">
                    Sao Tome &amp; Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Sudan">South Sudan</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syria">Syria</option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="Thailand">Thailand</option>
                  <option value="Togo">Togo</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad &amp; Tobago">
                    Trinidad &amp; Tobago
                  </option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Vatican City">Vatican City</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
              </div>

              <div className={styles.modalField}>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formContent.email}
                  onChange={handleChange}
                  required
                  className={styles.modalInput}
                  placeholder="Email Address"
                />
              </div>
              <div className={styles.modalField}>
                <textarea
                  id="message"
                  name="message"
                  value={formContent.message}
                  onChange={handleChange}
                  required
                  className={styles.modalInput}
                  placeholder="Your Message"
                  rows="4"
                />
              </div>
              <button type="submit" className={styles.submitButton}>
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
      <p data-aos="fade-up" className={styles.heading}>
        {service.heading}
      </p>
      <p data-aos="fade-up" className={styles.heading_content}>
        {service.content}
      </p>
      {service.contents.map((x, idx) =>
        idx % 2 === 0 ? (
          <div key={idx} className={`row ${styles.rowSpacing}`}>
            <div
              data-aos="fade-right"
              data-aos-offset="150"
              className="col-md-6"
            >
              {x.img1 && (
                <img src={x.img1} alt="" className={styles.contentImg} />
              )}
              {x.img2 && (
                <img src={x.img2} alt="" className={styles.contentImg} />
              )}
            </div>
            <div
              data-aos="fade-left"
              data-aos-offset="150"
              className="col-md-6"
            >
              <p className={`${styles.subHead}`}>{x.heading}</p>
              <p className={`${styles.heading_content} ${styles.justify}`}>
                {x.content}
              </p>
              <div className={styles.explanation}>
                {x.contentexplination.split("\n").map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div key={idx} className={`row ${styles.rowSpacing}`}>
            <div
              data-aos="fade-right"
              data-aos-offset="150"
              className="col-md-6"
            >
              <p className={`${styles.subHead}`}>{x.heading}</p>
              <p className={`${styles.heading_content} ${styles.justify}`}>
                {x.content}
              </p>
            </div>
            <div
              data-aos="fade-left"
              data-aos-offset="150"
              className="col-md-6"
            >
              <img src={x.img} alt="" className={styles.contentImg} />
            </div>
          </div>
        )
      )}

      {showSuccessPopup && (
        <div className={styles.popup}>
          <div className={styles.popupContent}>
            <h2>Success!</h2>
            <p>Your message has been sent successfully.</p>
            <button onClick={handleClosePopup} className={styles.closeBtn}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default IndividualService;
