import React from "react";
import styles from "./Footer.module.css";
import fb from "../../assets/home/facebook.png";
import linkdin from "../../assets/home/linkdin.png";
import insta from "../../assets/home/insta.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const companyDetails = [
    {
      name: "VYNX INTERNATIONAL PVT LTD",
      address: "Plot no - 282 Rajenderanagar, Hyderabad-India 500089",
      email: "info@vynxgroup.com",
      phone: "+91 91775 47901, +91 97052 41107",
    },
    {
      name: "VYNX INFORMATION TECHNOLOGY LLC",
      address: "Dubai Investment Park POB-1464",
      email: "info@vynxgroup.com",
      phone: "+97142966227",
    },
    {
      name: "VYNX TELECOM LLP",
      address: "DELAWARE 8 The Green STE-A 19901, United States Of America",
      email: "info@vynxtelecom.com",
      phone: "+1 248 875 6746, +91 91775 47901",
    },
    {
      name: "VYNX GLOBAL LLC",
      address: "Armenia 🇦🇲, Yerevan",
      email: "info@vynxglobal.com",
      phone: "+374 91 757677, +91 91775 47901",
    },
  ];

  return (
    <>
      <div
        style={{
          backgroundColor: "#2b2b2b",
          paddingTop: "60px",
          paddingBottom: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          {/* <p className={styles.logo}>VYNX</p> */}
          <p className={styles.logotext}> VYNX INTERNATIONAL PVT.LTD </p>
          <div className={styles.social_logo}>
            <img src={fb} alt="Facebook" className={styles.img} />
            <img src={linkdin} alt="LinkedIn" className={styles.img} />
            <img src={insta} alt="Instagram" className={styles.img} />
          </div>
        </div>

        <div className="container">
          <div className="row ">
            {companyDetails.map((company, index) => (
              <div
                key={index}
                className={`col-lg-3 col-sm-6 ${styles.contain}`}
              >
                <p className={styles.head}>{company.name}</p>
                <p className={styles.text}>{company.address}</p>
                <p className={styles.text}>
                  <a
                    href={`tel:${company.phone.replace(/,\s*/g, "")}`}
                    className={styles.link}
                  >
                    {company.phone}
                  </a>
                </p>
                <p className={styles.text}>
                  <a href={`mailto:${company.email}`} className={styles.link}>
                    {company.email}
                  </a>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#2b2b2b",
          borderTop: "solid 1px #707070",
          textAlign: "center",
          padding: "15px 0",
        }}
      >
        <p className={styles.bottom}>
          Copyright © {currentYear}. Vynx All rights reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
