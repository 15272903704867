import licence from "../../assets/Individualfreezone/1486956.jpg";
import DAFZA from "../../assets/Individualfreezone/DAFZA-min.jpg";
import DMCC from "../../assets/Individualfreezone/622087.jpg";
import Image from "../../assets/Individualfreezone/men.jpg";
import Silicon from "../../assets/Individualfreezone/3191626.jpg";
import IFZA from "../../assets/Individualfreezone/ifza.webp";
import JAFZA from "../../assets/Individualfreezone/11244322.jpg";
import Masdar from "../../assets/Individualfreezone/11244341.jpg";
import MFZ from "../../assets/Individualfreezone/5012751.jpg";
import Rakez from "../../assets/Individualfreezone/Rakez.webp";
import SHAMS from "../../assets/Individualfreezone/222725.jpg";
import shams from "../../assets/Individualfreezone/3b3.jpg";
import SPC from "../../assets/Individualfreezone/222788.jpg";

export const freeZoneData = [
  {
    id: 1,
    bannertext: "Abu Dhabi Global Market (ADGM) Freezone Company Setup",
    to: "/individualFreeZone?id=1",
    title: "Abu Dhabi Free Zone",
    description:
      "Establishing your business in the Abu Dhabi Global Market located on Al Maryah Island in the heart of Abu Dhabi, is a premier financial free zone offering a dynamic environment for business growth. Strategically positioned as a global trade and investment hub, ADGM operates under its own civil and commercial laws, ensuring transparency, compliance, and stability for businesses across various sectors.",
    bgrimg: licence,

    about:
      "ADGM Freezone provides businesses with a strategic location, state-of-the-art infrastructure, and a robust regulatory framework. As a business owner establishing your presence in ADGM",
    // aboutimg: Image,
    abouttitle: "Abu Dhabi Global Market ?",
    highlights: [
      {
        title: "Strategic Location",
        content:
          "Situated on Al Maryah Island, ADGM offers easy access to the Middle East, Africa, and South Asia, making it an ideal hub for regional and international trade.",
      },
      {
        title: "Regulatory Excellence",
        content:
          "ADGM operates under English Common Law, providing a transparent and predictable legal environment. This ensures that businesses operate with confidence and clarity.",
      },
      {
        title: "Business-Friendly Environment",
        content:
          "With a commitment to facilitating ease of business, ADGM provides a seamless process for company registration, licensing, and ongoing operations.",
      },
    ],
    head: "STARTING A BUSINESS IN ABU DHABI?",
    head2: "ADGM Free Zone Features",
    head3:
      "ADGM Freezone is designed to cater to the diverse needs of businesses, offering a range of features that include:",
    licenses: [
      {
        title: "World-Class Infrastructure",
        content:
          "High-quality office spaces, advanced IT systems, and comprehensive support services.",
      },
      {
        title: "Tax Benefits",
        content:
          "Enjoy a tax-free environment with no corporate taxes for 50 years and no personal income taxes.",
      },
      {
        title: "100% Foreign Ownership",
        content:
          "ADGM allows full foreign ownership of companies, providing flexibility and control over your business.",
      },
    ],
    stephead: "Steps for Company Formation in ADGM",
    stepdiscription:
      "Setting up a company in the ADGM Freezone is a streamlined process. Here’s a step-by-step guide to help you get started",
    process: [
      {
        title: "Choose Your Business Activity",
        content:
          "Determine the nature of your business and ensure it aligns with ADGM’s permitted activities.",
      },
      {
        title: "Select a Legal Structure",
        content:
          "Decide whether to establish a Limited Liability Company (LLC), a branch of a foreign company, or other legal entities.",
      },
      {
        title: "Register Your Company",
        content:
          "Submit your application along with the required documents, such as passport copies, business plans, and proof of address.",
      },
      {
        title: "Obtain Your License",
        content:
          "Once your application is approved, you will receive your business license, allowing you to commence operations.",
      },
    ],

    whyhead: "ADGM License Categories",
    whydiscription:
      "ADGM offers a variety of license categories to cater to different business needs:",
    whylist: [
      {
        title: "Financial Services",
        content:
          "Licenses for banks, insurance companies, investment firms, and more",
      },
      {
        title: "Non-Financial Services",
        content:
          "Includes professional services, consultancy, legal firms, and others.",
      },
      {
        title: "Retail",
        content:
          "Licenses for retail businesses operating within the ADGM premises.",
      },
      {
        title: "Obtain Your License",
        content:
          "Special licenses for technology-driven startups and innovation centers.",
      },
    ],

    clientReviews: [
      {
        name: "John Doe",
        review: "Great business opportunities and seamless process!",
      },
      {
        name: "Jane Smith",
        review: "The best Free Zone for tech startups!",
      },
      {
        name: "Alice Johnson",
        review: "Exceptional support for company formation in the UAE.",
      },
      {
        name: "Michael Brown",
        review: "A one-stop solution for all my business needs.",
      },
      {
        name: "Sophia Williams",
        review: "Quick and hassle-free documentation process.",
      },
      {
        name: "David Wilson",
        review: "Outstanding guidance throughout the company setup journey.",
      },
      {
        name: "Emma Davis",
        review: "Professional and knowledgeable team!",
      },
      {
        name: "Liam Garcia",
        review: "Highly recommend their services for global entrepreneurs.",
      },
      {
        name: "Olivia Martinez",
        review: "Reliable, efficient, and trustworthy service providers.",
      },
      {
        name: "Noah Robinson",
        review: "They made my business vision come true!",
      },
    ],
  },

  {
    id: 2,
    bannertext: "Company Formation in Dubai Airport Free Zone",
    to: "/individualFreeZone?id=1",
    title: "Dubai Airport Free Zone",
    description:
      "Establishing a business in the Dubai Airport Free Zone (DAFZA) offers a gateway to the dynamic market of Dubai and beyond. DAFZA provides a strategic location for companies looking to benefit from world-class infrastructure, proximity to Dubai International Airport, and access to a global business hub. Setting up your company in DAFZA ensures streamlined procedures, 100% foreign ownership, and zero percent corporate tax, making it an attractive choice for entrepreneurs and businesses seeking growth opportunities.",
    bgrimg: DAFZA,

    about:
      "DAFZA stands out as a premier destination for business setup due to its numerous advantages. The zone is strategically located next to Dubai International Airport, facilitating seamless international trade and logistics. Companies benefit from a tax-free environment, efficient customs procedures, and state-of-the-art facilities. Additionally, DAFZA’s business-friendly regulations and comprehensive support services simplify the setup process, allowing businesses to focus on their core operations.",
    // aboutimg: Image,
    abouttitle: "Business Setup in DAFZA ?",
    highlights: [
      {
        title: "Office Spaces",
        content:
          "Flexible office solutions including serviced offices, virtual offices, and customized office spaces.",
      },
      {
        title: "Warehousing",
        content:
          "State-of-the-art warehousing facilities for efficient storage and distribution.",
      },
      {
        title: "Retail Units",
        content:
          "Prime locations for retail businesses seeking high foot traffic and visibility.",
      },
      {
        title: "Business Support Services",
        content: "Access to administrative support, IT services, and more.",
      },
    ],
    head: "Types of Business Licenses Offered at DAFZA",
    head3:
      "DAFZA provides a variety of business licenses to accommodate different operational needs:",
    licenses: [
      {
        title: "Commercial License",
        content: "For businesses engaged in trading goods and services.",
      },
      {
        title: "Industrial License",
        content: "For manufacturing and industrial activities.",
      },
      {
        title: "Service License",
        content:
          "For businesses providing professional or consultancy services.",
      },
      {
        title: "E-commerce License",
        content:
          "For businesses operating online and engaging in digital transactions.",
      },
    ],
    stephead: "Dubai Airport Freezone License Packages Cost",
    stepdiscription:
      "DAFZA offers competitive license packages designed to provide value for money. Packages typically include",
    process: [
      {
        title: "Basic License Package",
        content: "Includes a business license and minimal office space.",
      },
      {
        title: "Standard Package",
        content:
          "Includes a business license, office space, and additional services.",
      },
      {
        title: "Premium Package",
        content:
          "Offers comprehensive services including larger office spaces, enhanced support services, and multiple licenses.",
      },
    ],

    whyhead: "Types of Business Setup in DAFZA",
    whydiscription:
      "DAFZA offers several business setup options to suit various requirements:",
    whylist: [
      {
        title: "Free Zone Company (FZC)",
        content:
          "Ideal for small to medium-sized enterprises with up to 5 shareholders.",
      },
      {
        title: "Free Zone Establishment (FZE)",
        content: "Suitable for single-shareholder companies.",
      },
      {
        title: "Branch Office",
        content:
          "Allows companies to operate a branch of their existing business within the free zone.",
      },
    ],

    extra: "List of Companies in DAFZA",
    extracontent:
      "DAFZA is home to a diverse range of international and local companies, including industry leaders and emerging businesses. Notable companies in DAFZA include multinational corporations, tech startups, logistics firms, and financial services providers. This diverse business environment fosters collaboration and innovation, enhancing opportunities for growth.",
  },

  {
    id: 3,
    bannertext: "Dubai Multi Commodities Centre (DMCC) Freezone Company Setup",
    to: "/individualFreeZone?id=1",
    bgrimg: DMCC,
    aboutimg: Image,
    name: "Approximate Processing Time",
    about:
      "Pre-approval: 7-10 business days\n" +
      "License: 2-3 business days\n" +
      "Establishment card: same day as license\n" +
      "Visa application process: 7 business days\n" +
      "E-channel: 24 hours",

    whyhead: "Types of business licenses:",
    whydiscription: `Popular business activities at DMCC:
      Commodities exchanges
    - Business Support Firms
    - Professional Services Companies
    - Marketing Companies
    - IT
    - Logistics & Shipping Companies
    - Banking & Finance
    - Insurance
    - Recruitment Agencies
    - Food & Beverage Distributors`,
    whylist: [
      {
        title: " Trading License",
        content:
          "To set up a business in Dubai that involves the buying and selling of goods. This license can cover companies in import and export, sales, general stores, etc. It offers the highest degree of flexibility & freedom to operate a business.",
      },
      {
        title: "Industrial License",
        content:
          "This license type is issued to businesses engaged in manufacturing activities that transform natural materials and resources into final products. This license covers industries such as gold and precious metals manufacturing.",
      },
      {
        title: "Service License",
        content:
          "This license type is issued to businesses that are service providers, artisans or craftsmen. Examples of companies that apply for this type of license include medical services, beauty salons and repair services.",
      },
    ],

    extra: "About DMCC",
    extracontent:
      "Located in Jumeirah Lake Towers(JLT) and established in 2002, Dubai Multi Commodities Centre(DMCC) is the ideal free zone for commodities trading, financial services and a large number of specialist industries ranging from agro to energy to diamonds, gold and base metals. Awarded “Global Free Zone of the Year” seven years in a row now, it brings together more than 20,000 businesses from all sectors and industries into the world’s most interconnected Free Zone.",
  },

  {
    id: 4,
    bannertext: "Dubai Silicon Oasis Company Formation",
    to: "/individualFreeZone?id=1",
    title: "DSOA Freezone",
    description:
      "Dubai Silicon Oasis Authority (DSOA) is an integrated free zone technology park that offers a unique business environment. Setting up a company here provides businesses with 100% ownership, access to a thriving tech community, and a strategic location in Dubai. The formation process is streamlined, allowing businesses to start operations quickly and efficiently.",
    bgrimg: Silicon,

    about:
      "DSOA offers various licenses tailored to different business needs, including:",
    // aboutimg: Image,
    abouttitle: "DSOA Licenses ",
    highlights: [
      {
        title: "Service License",
      },
      {
        title: "Trade License",
      },
      {
        title: "Industrial License",
      },
      {
        content:
          "The cost of these licenses is competitive, with flexible packages designed to suit businesses of all sizes. The pricing is transparent, ensuring that businesses can plan their budgets effectively.",
      },
    ],
    head: "Benefits of Business Setup in Dubai Silicon Oasis",
    head3:
      "Setting up a business in DSOA comes with numerous benefits, including:",
    licenses: [
      {
        content: "Access to a thriving tech community",
      },
      {
        content: "100% foreign ownership and repatriation of capital",
      },
      {
        content: "No personal or corporate income taxes",
      },
      {
        content:
          "A strategic location with easy access to Dubai's main business districts",
      },
      {
        content:
          "Cutting-edge infrastructure and facilities These advantages make DSOA an attractive option for businesses looking to thrive in the tech industry.",
      },
    ],
    stephead: "Steps in Setting Up Your Business in DSOA",
    stepdiscription:
      "The process of setting up a business in DSOA is straightforward and efficient:",
    process: [
      {
        title: "Define Your Business Activity",
        content: "Choose the appropriate business activity and license type.",
      },
      {
        title: "Submit Your Application",
        content:
          "Submit the required documents, including passport copies and a business plan.",
      },
      {
        title: "Obtain Your License",
        content: "Obtain initial approval and reserve a trade name.",
      },
      {
        title: "Complete Registration",
        content: "Finalize the office space or facility requirements.",
      },
      {
        title: "You're Done",
        content: "Receive the business license and start operations.",
      },
    ],

    extra: "Why Choose Decisive Zone for Your Company Setup in DSOA ?",
    extracontent:
      "Decisive Zone offers expert guidance and support for seamless company formation in Dubai Silicon Oasis (DSOA), leveraging its deep understanding of DSOA’s requirements and commitment to your success. Specializing in technology-driven industries such as software, hardware, mobile applications, and innovation, DSOA provides a vibrant ecosystem ideal for entrepreneurs and established businesses alike. With state-of-the-art facilities, including modern offices, R&D labs, conference rooms, and high-tech infrastructure, as well as on-site amenities like retail outlets, dining options, and residential areas, DSOA fosters collaboration and growth. Partnering with Decisive Zone ensures you navigate the complexities of business setup effortlessly, allowing you to focus on growing your business.",
  },

  {
    id: 5,
    bannertext: "FFZA Free Zone",
    to: "/individualFreeZone?id=1",
    title:
      "FFZA Free Zone Company Formation in Fujairah Free Zone Authority (FFZA)",
    description:
      "Fujairah Free Zone Authority (FFZA) offers a strategic location with direct access to major shipping routes, making it an ideal choice for international trade. The company formation process is streamlined, allowing businesses to establish their presence quickly and efficiently.",
    bgrimg: licence,

    about:
      "FFZA provides a tax-free environment, 100% foreign ownership, and easy access to global markets. Its proximity to Fujairah Port and Fujairah International Airport further enhances the ease of doing business, making it a preferred choice for companies looking to expand in the UAE.",
    // aboutimg: Image,
    abouttitle: "Business Setup in FFZA?",
    name: "Key Benefits of Setting Up",
    highlights: [
      {
        title: "Modern offices, warehouses, and industrial plots.",
      },
      {
        title: "Robust infrastructure for seamless business operations",
      },
      {
        title: "24/7 security and a wide range of support services.",
      },
    ],
    head: "STARTING A BUSINESS IN ABU UAE?",
    head2: "Types of Business Licenses Offered in FFZA",
    head3: "FFZA various business licenses to different industries:",
    licenses: [
      {
        title: "Trading Licenses",
        content: "For companies involved in buying and selling goods.",
      },
      {
        title: "Service Licenses",
        content: "For businesses offering professional services.",
      },
      {
        title: "Industrial Licenses",
        content: "For manufacturing and industrial activities.",
      },
      {
        title: "General Trading Licenses",
        content: "For companies involved in multiple trade activities.",
      },
    ],
    stephead: "Steps in Setting Up Your Business in FFZA",
    stepdiscription:
      "Setting up a company in the ADGM Freezone is a streamlined process. Here’s a step-by-step guide to help you get started",
    process: [
      {
        title: "Choose Your Business Activity",
        content: "Select the type of business activity you wish to undertake.",
      },
      {
        title: "Select a Company Name",
        content: "Ensure your company name complies with FFZA regulations.",
      },
      {
        title: "Submit Required Documents",
        content:
          "Provide all necessary documentation, including passport copies and business plans.",
      },
      {
        title: "Obtain Initial Approval",
        content: "Receive preliminary approval from FFZA.",
      },
      {
        title: "Sign the Lease Agreement",
        content: "Secure your business premises within the free zone.",
      },
      {
        title: "Receive Your Business License",
        content: "Obtain your business license and commence operations.",
      },
    ],

    extra: "Why Choose Decisive Zone for Your Company Setup in FFZA?",
    extracontent:
      "Decisive Zone simplifies the company formation process in FFZA, offering expert guidance and personalized services. Our team ensures a hassle-free setup, from documentation to licensing, allowing you to focus on your business growth. Trust Decisive Zone to be your partner in success.",
  },

  {
    id: 6,
    bannertext: "IFZA",
    to: "/individualFreeZone?id=1",
    title: "IFZA International Free Zone Authority",
    description:
      "Decisive Zone and IFZA make your company setup seamless with free consultations, tailored solutions, and exclusive benefits. Take advantage of a limited-time offer for a free visa for life, while enjoying a swift and efficient process: business licenses are issued in 3-5 business days, establishment cards in 2-3 business days, and visa applications are completed within 7 business days.",
    bgrimg: IFZA,
    about:
      "International Free Zone Authority (IFZA) has now become a global hub for new business setup in the UAE, offering highly competitive pricing and a wide range of business activities. An IFZA company setup is a popular choice for those seeking the most cost-effective general trading company license in the UAE. Other licenses such as consultancy, service, commercial trading, industrial and holding licenses are also issued by IFZA. IFZA facilitates quick company registration and licenses are issued in 2-3 working days.",
    aboutimg: IFZA,
    abouttitle: "International Free Zone Authority (IFZA)",
    head: "STARTING A BUSINESS WITH IFZA ?",
    head2: "Types of business licenses",
    head3:
      "Explore the unique advantages of establishing your business in the UAE with International Free Zone Authority (IFZA)",
    licenses: [
      {
        title: "Commercial License",
        content:
          "A commercial license provides you with the necessary legal rights to initiate import, export, storage, and distribution procedures.",
      },
      {
        title: "Professional License",
        content:
          "Consultancy businesses and service providers are expected to have professional license issues for them. A professional license is issued by the DED.",
      },
      {
        title: "Industrial License",
        content:
          "An industrial license is applicable for those companies who wish to import raw materials in their vicinity. Manufacturing and processing of a few selected goods are made legal only if you have an industrial license.",
      },
      {
        title: "Consultancy License",
        content:
          "To provide expert consultancy services for professionals you must have a consultancy license in IFZA.",
      },
      {
        title: "Service License",
        content:
          "Manufacturing, production, and transportation of services are the key activities allowed for those who have service licenses issued under their companies name.",
      },
      {
        title: "General Trading License",
        content:
          "A wide range of services and trading opportunities become available to you if you own an IFZA general trading license. A general trade license allows you to initiate trading of your goods and services in a B2B, B2C, and a C2B business model.",
      },
    ],
    stephead: "Steps in Setting Up Your Business in FFZA",
    stepdiscription:
      "Setting up a company in the ADGM Freezone is a streamlined process. Here’s a step-by-step guide to help you get started",
    process: [
      {
        title: "CONSULTATION & PROPOSAL",
        content:
          "Establish your License requirements and share detailed proposal.",
      },
      {
        title: "DOCUMENTATION",
        content:
          "Obtain and assemble the essential documents required to process.",
      },
      {
        title: "PROCESSING",
        content:
          "Kick-start the process of getting approvals and license issuance.",
      },
      {
        title: "LICENSE APPROVAL & ISSUANCE",
        content:
          "Once approved, 2 forms will be issued one for digital signature / soft copy. License will be available for collection.",
      },
    ],

    whyhead: "How Decisive Zone Can Support Your Transition",
    whydiscription:
      "At DecisiveZone, we work closely with IFZA to ensure the optimal establishment of your company. Schedule a free consultation with our company formation specialists to determine the right business activities, visa, and shareholder requirements, and avail exclusive IFZA offers. Here’s what you gain with DecisiveZone and IFZA offers through our expert assistance",
    whylist: [
      {
        title: "Experience a Smooth Arrival",
        content: "Enjoy free airport pick-up and drop-off",
      },
      {
        title: "Seamless Accommodation",
        content: "Let us handle your hotel reservation",
      },
      {
        title: "Navigating the Local Landscape",
        content: "Benefit from expert business consultation",
      },
      {
        title: "Unlocking Opportunities",
        content: " Tap into our network for market insights",
      },
      {
        title: "Navigating Regulations",
        content: "We'll guide you through licensing and setup",
      },
    ],

    extra: "WHY IFZA?",
    extracontent:
      "Setting up a company in the International Free Zone Authority (IFZA) provides numerous benefits, including 100% business ownership, zero corporate and personal income tax, full profit repatriation, and a streamlined incorporation process. With cost-effective solutions tailored to your business needs, IFZA is open to companies of all sizes and industries, offering a business-friendly environment in a strategic location with easy market access and cutting-edge infrastructure. Enjoy transparent pricing with no hidden costs and expert guidance to ensure a seamless setup experience. **ENQUIRE NOW** to take advantage of this cost-effective and efficient business solution.",
  },

  {
    id: 7,
    bannertext: "Jebel Ali Free Zone",
    to: "/individualFreeZone?id=1",
    title: "Company Formation in Jebel Ali Free Zone Authority (JAFZA)",
    description:
      "JAFZA offers a prime location for businesses aiming to establish a strong presence in Dubai. You can choose from various entity types, including Free Zone Establishments (FZE), Free Zone Companies (FZCO), and branches of existing companies, making the formation process seamless and straightforward.",
    bgrimg: JAFZA,
    about:
      "JAFZA is one of the UAE’s most reputable free zones, providing 100% foreign ownership, zero corporate tax, and direct access to regional markets. Its proximity to Jebel Ali Port and Al Maktoum International Airport makes it an ideal hub for global trade.",
    // aboutimg: IFZA,
    abouttitle: "Business Setup in JAFZA?",
    highlights: [
      {
        title: "Warehouses",
        content:
          "Ready-to-use warehouse spaces designed to meet the storage needs of various industries.",
      },
      {
        title: "Office Spaces",
        content:
          "Flexible office solutions, including fully serviced offices, co-working spaces, and customized office setups.",
      },
      {
        title: "Customizable Plots of Land",
        content:
          "Options to lease land for long-term projects, allowing businesses to build facilities tailored to their requirements.",
      },
      {
        title: "Business Centers",
        content:
          "Fully equipped centers offering administrative support, meeting rooms, and business services.",
      },
      {
        title: "On-Site Customs",
        content:
          "Streamlined customs services for hassle-free import and export activities.",
      },
      {
        title: "Efficient Logistics Services",
        content:
          "Integrated logistics support, including transportation and warehousing solutions.",
      },
    ],

    head: "STARTING A BUSINESS IN DUBAI ?",
    head2: "Types of business licenses offered at JAFZA",
    head3:
      "JAFZA offers a variety of licenses tailored to different business activities, including:",
    licenses: [
      {
        title: "Trading License",
        content:
          "For companies engaged in import, export, distribution, and storage of specified products.",
      },
      {
        title: "Industrial License",
        content:
          "For manufacturing companies involved in production, assembly, or packaging of goods.",
      },
      {
        title: "E-commerce License",
        content:
          "Enables businesses to conduct commercial activities over the internet, including online sales and services.",
      },
      {
        title: "Service License",
        content:
          "Allows businesses to provide various services like consulting, IT, marketing, and more.",
      },
    ],

    whyhead: "List of Companies in JAFZA",
    whydiscription:
      "JAFZA is home to over 7,000 companies, ranging from Fortune 500 companies to SMEs. These businesses span various industries, including: ",
    whylist: [
      {
        title: "Logistics",
        content:
          "Companies specializing in transportation, warehousing, and supply chain management.",
      },
      {
        title: "Manufacturing",
        content:
          "Enterprises involved in producing goods, from electronics to consumer products.",
      },
      {
        title: "Electronics",
        content:
          "Leading technology firms focused on innovation and distribution of electronic products.",
      },
      {
        title: "Automotive",
        content:
          "Renowned car manufacturers and parts suppliers leveraging JAFZA’s strategic location.",
      },
      {
        title: "Food & Beverage",
        content:
          "Global food companies involved in production, processing, and distribution.",
      },
    ],

    extra: "Why Choose Decisive Zone for Your JAFZA Company Setup?",
    extracontent:
      "Decisive Zone provides expert guidance, transparent pricing, and end-to-end support for setting up your company in JAFZA. Our team ensures that the setup process is smooth, efficient, and aligned with your business objectives. We help you navigate legal requirements, choose the right business structure, and manage all the paperwork, so you can focus on growing your business.",
  },

  {
    id: 8,
    bannertext: "Khalifa Industrial Zone Abu Dhabi (KIZAD) Company Setup",
    to: "/individualFreeZone?id=1",
    title: "KIZAD Free Zone",
    description:
      "KIZAD Abu Dhabi offers a premier location for businesses aiming to establish a foothold in the UAE. Situated in Khalifa Industrial Zone Abu Dhabi (KIZAD), this free zone provides an efficient setup process supported by modern infrastructure.",
    bgrimg: JAFZA,
    about:
      "Key Benefits of Setting Up in KIZAD Free Zone Setting up your business in KIZAD Free Zone comes with numerous advantages:",
    // aboutimg: IFZA,
    abouttitle: "KIZAD ",
    highlights: [
      {
        title: "Strategic Location",
        content:
          "Located in Abu Dhabi, KIZAD provides excellent connectivity to key transport routes and global markets.",
      },
      {
        title: "100% Foreign Ownership",
        content:
          "Operate with full control over your business, without the need for a local partner.",
      },
      {
        title: "Tax Advantages",
        content: "Enjoy zero corporate and personal income taxes.",
      },
      {
        title: "Customs Duty Exemptions",
        content: "Benefit from exemptions on import and export duties.",
      },
      {
        title: "World-Class Infrastructure",
        content:
          "Access to top-tier facilities, including office spaces, warehouses, and industrial plots.",
      },
    ],

    head: "STARTING A BUSINESS IN ABU DHABI ?",
    head2: "Available Licenses in KIZAD Free Zone",
    head3: "KIZAD offers various licenses to suit different business needs",
    licenses: [
      {
        title: "Trading License",
        content:
          "For companies engaged in import, export, distribution, and storage of specified products.",
      },
      {
        title: "Industrial License",
        content:
          "For manufacturing companies involved in production, assembly, or packaging of goods.",
      },
      {
        title: "E-commerce License",
        content:
          "Enables businesses to conduct commercial activities over the internet, including online sales and services.",
      },
      {
        title: "Service License",
        content:
          "Allows businesses to provide various services like consulting, IT, marketing, and more.",
      },
    ],

    stephead: "Steps to Set Up Your Company in KIZAD Free Zone",
    stepdiscription: "Follow these steps to set up your business in KIZAD ",
    process: [
      {
        title: "Define Your Business Activity",
        content:
          "Select the appropriate license and setup type based on your business objectives.",
      },
      {
        title: "Submit Your Application",
        content: "Complete and submit the necessary forms and documentation.",
      },
      {
        title: "Obtain Your License",
        content: "Secure your business license from KIZAD authorities.",
      },
      {
        title: "Lease Facilities",
        content:
          "Choose and lease office space, warehouse, or industrial plots.",
      },
      {
        title: "Complete Registration",
        content:
          "Finalize your business registration and fulfill any additional legal requirements.",
      },
    ],

    whyhead: "Why Choose Decisive Zone for Your KIZAD Business Setup ?",
    whydiscription:
      "Decisive Zone is your trusted partner for establishing a business in KIZAD Abu Dhabi. Here’s why you should choose us:",
    whylist: [
      {
        title: "Expert Guidance",
        content:
          "Our experienced team provides comprehensive support throughout the setup process, ensuring all requirements are met efficiently.",
      },
      {
        title: "Transparent Pricing",
        content:
          "We offer clear and competitive pricing with no hidden fees, allowing you to plan your budget with confidence.",
      },
      {
        title: "Streamlined Process",
        content:
          "We handle all aspects of the business setup, including documentation, licensing, and registration, to ensure a smooth and hassle-free experience.",
      },
      {
        title: "Local Knowledge",
        content:
          "Our in-depth understanding of KIZAD Free Zone and Abu Dhabi’s business environment helps us tailor solutions to your specific needs.",
      },
      {
        title: "Comprehensive Services",
        content:
          "From selecting the right license to finding the perfect office space, we offer a full range of services to facilitate your business establishment.",
      },
      {
        title: "Customer Focus",
        content:
          "We prioritize your business needs and provide personalized service to support your success in the UAE market.",
      },
    ],

    extra: "Business Setup Options in KIZAD",
    extracontent:
      "In KIZAD Free Zone, you can choose from several business setup options to suit your needs: Free Zone Establishments (FZE), ideal for single-shareholder entities; Free Zone Companies (FZCO), perfect for multiple-shareholder businesses; and Branch Offices, designed for existing companies looking to expand into Abu Dhabi.",
  },

  {
    id: 9,
    bannertext: "Company Formation in Masdar City Free Zone",
    to: "/individualFreeZone?id=1",
    title: "FFZA Free Zone",
    description:
      "Masdar City Free Zone offers a futuristic business environment designed for innovation and sustainability. The company formation process is efficient, providing a streamlined pathway to establish your business in this cutting-edge free zone.",
    bgrimg: Masdar,
    about:
      "Business setup in Masdar City Free Zone offers an innovative environment, making it ideal for tech and sustainable businesses. With a strategic location near Abu Dhabi International Airport and key transportation hubs, it provides easy access to global markets. Additionally, businesses can enjoy tax benefits such as 100% foreign ownership and tax-free profits, creating a favorable environment for growth and expansion.",
    abouttitle: " Setup in Masdar City Free Zone?",
    highlights: [
      {
        title: "Modern Office Spaces",
        content: "Flexible workspaces designed for various business needs.",
      },
      {
        title: "Advanced Infrastructure",
        content: "State-of-the-art technology and eco-friendly facilities.",
      },
      {
        title: "Support Services",
        content:
          "Comprehensive business support including IT, legal, and administrative services.",
      },
    ],
    head: "STARTING A BUSINESS WITH UAE?",
    head2: "Types of Business Licenses Offered in Masdar City Free Zone",
    head3:
      "FFZA provides various business licenses to cater to different industries ",
    licenses: [
      {
        title: "Commercial License",
        content: "For trading and import/export activities.",
      },
      {
        title: "Technology Licenses",
        content: "For businesses focused on research and innovation.",
      },
      {
        title: "Industrial License",
        content: "For manufacturing and industrial operations.",
      },
      {
        title: "Service License",
        content: "For providing professional and consultancy services.",
      },
    ],

    stephead: "Steps for Business setup in Masdar City Free Zone",
    process: [
      {
        title: "SELECT YOUR BUSINESS ACTIVITY",
        content: "Choose the appropriate business activity and license type.",
      },
      {
        title: "SUBMIT APPLICATION",
        content:
          "Provide necessary documents and complete the application process.",
      },
      {
        title: "OBTAIN APPROVAL",
        content:
          "Receive initial approval from the Masdar City Free Zone authority.",
      },
      {
        title: "LEASE OFFICE SPACE",
        content: "Secure your business premises within the free zone.",
      },
      {
        title: "PAY FEES",
        content: "Complete payment for licensing and leasing.",
      },
      {
        title: "RECEIVE LICENSE",
        content: "Obtain your business license and start operations.",
      },
    ],

    whyhead: "Requirements for Obtaining a Masdar City Free Zone License",
    whydiscription: " To obtain a license, you’ll need ",
    whylist: [
      {
        content: "Enjoy free airport pick-up and drop-off.",
      },
      {
        content: "Passport copies of shareholders and managers.",
      },
      {
        content: "Proof of business address.",
      },
      {
        content: "A detailed business plan.",
      },
    ],

    extra:
      "Why Choose Decisive Zone for Your Company Setup in Masdar City Free Zone?",
    extracontent:
      "Decisive Zone offers expert support for setting up your business in Masdar City Free Zone. We handle all aspects of the setup process, ensuring a smooth and efficient experience. Trust us to provide personalized solutions that align with your business goals.",
  },

  {
    id: 10,
    bannertext: "Meydan Free Zone",
    to: "/individualFreeZone?id=1",
    title: "Company Formation in Meydan Free Zone",
    description:
      "Meydan Free Zone (MFZ) offers an ideal environment for businesses looking to establish themselves in Dubai. With a straightforward setup process, businesses can benefit from 100% ownership, no corporate tax, and a strategic location. The zone supports a wide range of activities, making it suitable for startups and established companies alike.",
    bgrimg: MFZ,
    about:
      "Meydan Free Zone offers a dynamic business environment with modern infrastructure, a strategic location, and business-friendly policies. It provides various business licenses across industries, making it an ideal choice for entrepreneurs.",
    // aboutimg: IFZA,
    abouttitle: " Business Setup in MFZ? ",
    highlights: [
      {
        content:
          "MFZ boasts state-of-the-art facilities, including fully serviced offices, meeting rooms, and virtual office solutions. Businesses can also access high-speed internet, ample parking, and 24/7 security, ensuring a seamless operational experience. These facilities are designed to meet the needs of businesses, from small startups to large corporations.",
      },
    ],

    head: "STARTING A BUSINESS IN DUBAI?",
    head2: "Types of Business Licenses Offered in Meydan Free Zone",
    head3:
      "Meydan Free Zone offers several types of business licenses, including",
    licenses: [
      {
        title: "Trading License",
      },
      {
        title: "Consultancy License",
      },
      {
        title: "Media License",
      },
      {
        title: "E-commerce License",
      },
    ],

    stephead: "Steps in Setting Up Your Business in Meydan Free Zone",
    stepdiscription:
      "Setting up a business in MFZ involves the following steps:",
    process: [
      {
        title: "Choose Your Business Activity",
        content: "Select the appropriate business activity and license type.",
      },
      {
        title: "Submit Required Documents",
        content:
          "Submit the required documents and application to the free zone authority.",
      },
      {
        title: "Obtain Initial Approval",
        content: "Obtain initial approval and reserve a trade name.",
      },
      {
        title: "Finalize Office Setup",
        content: "Finalize the office space or virtual office setup.",
      },
      {
        title: "Receive Business License",
        content: "Receive the business license and commence operations.",
      },
    ],

    whyhead: "Requirements for Obtaining a Meydan Free Zone License",
    whydiscription:
      "To obtain a license in MFZ, businesses need to submit the necessary documentation, including passport copies, proof of residence, and a business plan. The process is streamlined, with the free zone authority providing support at every step. Ensuring compliance with the requirements will facilitate a smooth license acquisition.",

    extra: "Why Choose Decisive Zone for Your Company Setup in MFZ?",
    extracontent:
      "Decisive Zone offers expert guidance and comprehensive support for businesses looking to establish themselves in Meydan Free Zone. With in-depth knowledge of the setup process and close ties with the free zone authority, Decisive Zone ensures a hassle-free experience, from license application to operational setup. Partnering with Decisive Zone means choosing efficiency, expertise, and a dedicated team committed to your success.",
  },

  {
    id: 11,
    bannertext: "RAKEZ",
    to: "/individualFreeZone?id=1",
    title: "Ras Al Khaimah Economic Zone",
    description:
      "DecisiveZone and RAKEZ offer a seamless company setup with free consultation, tailored solutions, and exclusive benefits. The process includes obtaining a license within 2-3 business days, an establishment card in 3-5 business days, and completing the visa application in 7 business days. Additionally, the E-Channel process takes just 24 hours, ensuring a quick and efficient setup.",
    bgrimg: Rakez,
    about:
      "Situated in the heart of Ras Al Khaimah, Ras Al Khaimah Economic Zone (RAKEZ) is a powerhouse UAE hub that offers customizable solutions within an efficient ecosystem. RAKEZ has a particularly environmentally friendly approach and is flourishing in over 50 industries. From freelancers and startups to SME’s and industrialists, catering to media, academia, business and industrial activities.",
    aboutimg: Rakez,
    abouttitle: "RAKEZ ",

    head: "STARTING A BUSINESS WITH RAKEZ",
    head2: "Types of business licenses",
    head3:
      "Explore the unique advantages of establishing your business in the UAE with Ras Al Khaimah Economic Zone (RAKEZ)",
    licenses: [
      {
        title: "Commercial",
        content: "Trading in services and goods.",
      },
      {
        title: "Educational",
        content:
          "Starting education-related institution or consultancy company.",
      },
      {
        title: "E-Commerce",
        content: "Trading in goods and services via electronic means.",
      },
      {
        title: "Professional",
        content: "For individuals who provide professional services.",
      },
      {
        title: "Individual / Professional",
        content:
          "One owner with one activity in a specific speciality or expertise.",
      },
      {
        title: "General Trading",
        content: "Trading in multiple goods.",
      },
      {
        title: "Industrial",
        content: "Manufacturing, importing, packaging, and exporting products.",
      },
      {
        title: "Media",
        content: "Operating media-related business activities.",
      },
      {
        title: "Service",
        content: "Conducting services or consultancies across any industry.",
      },
      {
        title: "Freelancer Permit",
        content: "For media, education, IT, and analysts professionals.",
      },
    ],

    stephead: "How to start a business with RAKEZ?",
    stepdiscription: "3 Simple Steps to Start a Business with RAKEZ",
    process: [
      {
        title: "CUSTOMIZE YOUR PACKAGE",
        content:
          "Establish your License requirements and share detailed proposal.",
      },
      {
        title: "SUBMIT APPLICATION & DOCUMENTS",
        content: "Effortlessly submit your documents and application.",
      },
      {
        title: "RECEIVE YOUR BUSINESS LICENSE",
        content: "Get your business license hassle-free.",
      },
    ],

    extra: "Benefits of setting up a company in RAKEZ",
    extracontent:
      "Cost-effective business incorporation solutions, accessibility to logistical hubs and highways, international spread of regional offices, access to the customer portal, and an express and streamlined business setup process with a wide choice of activities and different types of facilities.",
  },

  {
    id: 12,
    bannertext: "Sharjah Media City Free Zone (SHAMS) Freezone Company Setup",
    to: "/individualFreeZone?id=1",
    bgrimg: SHAMS,

    about:
      "The approximate processing time for business setup is as follows: License – 1-2 business days, Establishment – 10 business days, Visa application process – 7 business days, and E-channel – 24 hours.",
    aboutimg: shams,
    abouttitle: "Sharjah Media City Free Zone",

    head: "Advantages of SHAMS license",
    head3:
      "Setting up a business in DSOA comes with numerous benefits, including:",
    licenses: [
      {
        content:
          "Wide range of business activities available on one business license",
      },
      {
        content: "Company documents issued within days",
      },
      {
        content: "100% foreign ownership",
      },
      {
        content: "Physical presence not required for incorporation",
      },
      {
        content: "Companies have limited liability",
      },
      {
        content: "0% corporate or personal income tax",
      },
      {
        content:
          "Ability to appoint corporate directors and managers for your company",
      },
      {
        content:
          "Located 15 minutes from Sharjah & 30 minutes from Dubai International airports respectively",
      },
    ],

    extra: "About SHAMS",
    extracontent:
      "Launched in early 2017, Sharjah Media City (SHAMS) is a relatively new free zone targeting media startups. Not only is Sharjah the third largest emirate, but it also borders Dubai, giving businesses the desired proximity whilst catering to budget-conscious setups making it a sough-after location. SHAMS caters to businesses of all sizes, from small startups to corporate giants.",
  },

  {
    id: 13,
    bannertext:
      "Sharjah Publishing City Free Zone (SPC) Freezone Company Setup",
    to: "/individualFreeZone?id=1",
    description:
      "Sharjah Publishing City Free Zone (SPC) offers a streamlined company setup for businesses in publishing, media, and related industries, providing tax benefits, 100% foreign ownership, and world-class infrastructure for growth and innovation.",
    bgrimg: SPC,

    about:
      "Sharjah Publishing City Free Zone(SPC) is one of the latest free zones headquartered in Sharjah. SPC’s core licensing activities are publishing and printing but it also issues licenses for a huge number of activities including general trading, consultancies, e-commerce, IT, etc. It boasts of beautiful offices, a strategic location & one of the shortest incorporation times.",
    // aboutimg: Image,
    abouttitle: "Sharjah Publishing City Free Zone?",
    highlights: [
      {
        title: "License",
        content: "5 business days",
      },
      {
        title: "Establishment",
        content: "3 business days",
      },
      {
        title: "E-channel",
        content: "24 hours",
      },
    ],
    head: "STARTING A BUSINESS IN SHARJAH?",
    head2: "Advantages of SPC license",
    head3:
      "SPC Freezone is designed to cater to the diverse needs of businesses, offering a range of advantages that include:",
    licenses: [
      {
        title: "Tax Benefits",
        content: "0% corporate or personal income tax.",
      },
      {
        title: "Foreign Ownership",
        content: "100% Foreign Ownership Guarantee.",
      },
      {
        title: "Capital Share",
        content: "No Paid-Up Share Capital Required for Business Setup.",
      },
      {
        title: "Bank Account",
        content: "Easy to set up a corporate bank account.",
      },
      {
        title: "Trade License",
        content: "Option of up to a 10-year trade license.",
      },
      {
        title: "Business Activities",
        content: "More than 1,500 business license activities.",
      },
      {
        title: "Flexibility",
        content: "Flexibility on the number of work visas.",
      },
      {
        title: "Multiple Activities",
        content:
          "Combine up to 5 activities from different sectors under 1 license.",
      },
    ],

    whyhead: "Types of business licenses",
    whylist: [
      {
        title: " E-Commerce License",
        content:
          "An e-commerce license allows you to conduct your business in the UAE but purely online. You’d operate via online portals that will allow you to buy and sell your goods & services both locally and internationally.",
      },
      {
        title: " Service License",
        content:
          "Operating with a Service License means you will run a business without any tangible assets, you focus more on selling unique services to your consumer (i.e. Consultancies, Project Management, Investment Services, etc.)",
      },
      {
        title: " Commercial License",
        content:
          "Operating with a Commercial License allows you to trade in goods and sell specific services. Exported and sold outside the UAE and traded solely within the UAE. With this license, you can trade almost any type of goods.",
      },
      {
        title: " General Trading License",
        content:
          "A General Trading License allows you to trade in more specific goods as well as general items. Almost all goods can be traded under this license and they do not need to be related.",
      },
    ],
  },
];
