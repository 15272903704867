import React, { useState } from "react";
import styles from "./ProposalPage.module.css";
import img from "../../assets/ss.png";

const ProposalPage = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleSelectPackage = (option) => {
    setSelectedOption(option);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className={styles.container}>
      <section className={styles.firstSection}>
        <div className={styles.header}>
          <img src={img} alt="Decisive Zone Logo" className={styles.logo} />
        </div>

        <div className={styles.content}>
          {/* <p>Hi {firstname}</p> */}
          <p>
            Thank you for submitting your request for {} proposal. We have
            prepared this document to outline how business setup would work for
            you based on the details you provided in our Cost Calculator:
          </p>
          <ul className={styles.details}>
            <li>Number of Visas: {} </li>
            <li>Number of Shareholders: {} </li>
          </ul>
          <p>
            This estimate includes indicative costs for two Dubai free zone
            options and one Northern Emirate option. Please note that these
            "starting from" prices may vary based on further discussions about
            your specific needs.
          </p>
          <p>
            If you’d like to schedule a call or arrange a free, no-obligation
            consultation with our Company Formation Team, contact us at{" "}
            <a href="mailto:support@vynxbusiness.com" className={styles.link}>
              support@vynxbusiness.com
            </a>{" "}
            or{" "}
            <a
              href="https://wa.me/1234567890"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              reach us on WhatsApp
            </a>
            .
          </p>
          <p className={styles.signature}>Kind regards,</p>
          <p className={styles.signatureName}>
            <strong>MOIZ MOHIUDDIN MOHAMMED</strong>
          </p>
          <p className={styles.signatureRole}>Managing Partner</p>
          <p>
            <a href="mailto:moiz@vynxbusiness.com" className={styles.link}>
              moiz@vynxbusiness.com
            </a>
          </p>
        </div>
      </section>

      <section className={styles.cardsSection}>
        <h2 className={styles.optionsTitle}>Options</h2>
        <p className={styles.optionsDescription}>
          Here are 3 estimates for options that we think may suit your
          requirements:
        </p>

        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <h3 className={styles.cardTitle}>Starter Package</h3>
            <ul className={styles.cardList}>
              <li>Northern emirate address for your business</li>
              <li>100% Company ownership</li>
              <li>No paid-up share capital</li>
              <li>Remote setup for license</li>
              <li>Co-working facilities access</li>
              <li>2000+ business activities</li>
              <li>No NOC from current sponsor needed</li>
              <li>Single business activity</li>
            </ul>
            <p className={styles.cardPrice}>
              <strong>From:</strong> AED 12,010
            </p>
            <button
              className={styles.selectButton}
              onClick={() => handleSelectPackage("Starter Package")}
            >
              Select This Package
            </button>
          </div>

          <div className={`${styles.card} ${styles.popular}`}>
            <h3 className={styles.cardTitle}>Business Pro</h3>
            <ul className={styles.cardList}>
              <li>Dubai address for your business</li>
              <li>100% Company ownership</li>
              <li>No paid-up share capital</li>
              <li>Remote setup for license</li>
              <li>Co-working facilities access</li>
              <li>2000+ business activities</li>
              <li>No NOC from current sponsor needed</li>
              <li>Multiple business activity</li>
              <li>Visa free for life</li>
            </ul>
            <p className={styles.cardPrice}>
              <span className={styles.discountedPrice}>AED 18,245</span>{" "}
              <del className={styles.originalPrice}>AED 21,995</del>
            </p>
            <button
              className={styles.selectButton}
              onClick={() => handleSelectPackage("Business Pro")}
            >
              Select This Package
            </button>
          </div>

          <div className={styles.card}>
            <h3 className={styles.cardTitle}>Business Elite</h3>
            <ul className={styles.cardList}>
              <li>Business pro package</li>
              <li>Fast track medical and biometrics</li>
              <li>Corporate bank account</li>
              <li>Corporation tax registration</li>
              <li>VAT registration</li>
              <li>Company Stamp</li>
              <li>Dedicated relationship manager for your business needs</li>
            </ul>
            <p className={styles.cardPrice}>
              <strong>From:</strong> AED 29,999
            </p>
            <button
              className={styles.selectButton}
              onClick={() => handleSelectPackage("Business Elite")}
            >
              Select This Package
            </button>
          </div>
        </div>
      </section>

      {showPopup && (
        <div className={styles.popup}>
          <div className={styles.popupContent}>
            <h3>Thank You!</h3>
            <div className={styles.checkIcon}>
              <i className="fas fa-check-circle"></i>{" "}
            </div>
            <p>
              Thank you for selecting <strong>{selectedOption}</strong>. One of
              our advisors will be in touch with you shortly.
            </p>
            <button onClick={closePopup} className={styles.closeButton}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProposalPage;
