import React from "react";
import Costcaliculation from "../../components/Ccaliculation";
import Footer from "../../components/Footer";

const ccaliculation = () => {
  return (
    <>
      {/* Form Section  */}
      <section>
        <Costcaliculation />
      </section>

      {/* Footer Section  */}
      <section>
        <Footer />
      </section>
    </>
  );
};

export default ccaliculation;