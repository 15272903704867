import React, { useState } from "react";
import styles from "./Ccaliculation.module.css";

const Costcaliculation = () => {
  const [formContent, setFormContent] = useState({
    businessActivity: "",
    visasRequired: "",
    shareholders: "",
    officeSpace: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    nationality: "",
    countryCode: "",
  });

  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");

  const handleCountryChange = (e) => {
    setSelectedCountryCode(e.target.value);
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setFormContent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formContent.email || !formContent.firstName || !formContent.lastName) {
      alert("Please fill in all required fields.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch("http://localhost:3110/api/email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: formContent.email.trim(),
          firstName: formContent.firstName.trim(),
          lastName: formContent.lastName.trim(),
        }),
      });
      setIsLoading(false);

      if (!response.ok) {
        const errorData = await response.json();
        alert(`Error: ${errorData.error || "Something went wrong."}`);
        return;
      }
      const data = await response.json();
      console.log("Response from server:", data);
      alert("Email sent successfully!");
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error.message);
      alert("Failed to send email. Please try again.");
    }
  };

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  return (
    <div className={`container ${styles.container}`}>
      <p className={styles.heading}>Cost Calculation</p>

      {step === 1 && (
        <form
          className={`${styles.formContainer} mb-4`}
          onSubmit={(e) => e.preventDefault()}
          data-aos="fade-up"
        >
          {/* Business Activity */}
          <div style={{ textAlign: "left", marginBottom: "20px" }}>
            <label className={styles.label}>Business Activity</label>
            <select
              name="businessActivity"
              value={formContent.businessActivity}
              onChange={handleChange}
              className={styles.input}
              required
            >
              <option value="" disabled>
                Select a business activity
              </option>
              <option value="General Trading">General Trading</option>
              <option value="IT Trading">IT Trading</option>
              <option value="E-commerce">E-commerce</option>
              <option value="Food Trading">Food Trading</option>
              <option value="Business Consultancy">Business Consultancy</option>
              <option value="IT Consultancy">IT Consultancy</option>
              <option value="Automobile Trading">Automobile Trading</option>
              <option value="Technical Services">Technical Services</option>
              <option value="Textile Trading">Textile Trading</option>
              <option value="Digital Marketing">Digital Marketing</option>
              <option value="Salon">Salon</option>
              <option value="Event Management">Event Management</option>
              <option value="Advertising">Advertising</option>
              <option value="Perfumes &amp; Cosmetics Trading">
                Perfumes &amp; Cosmetics Trading
              </option>
              <option value="Raw Materials Trading">
                Raw Materials Trading
              </option>
              <option value="Jewellery Trading">Jewellery Trading</option>
              <option value="Educational Support Activities">
                Educational Support Activities
              </option>
              <option value="Engineering Consultancy">
                Engineering Consultancy
              </option>
              <option value="Tourism &amp; Recreation">
                Tourism &amp; Recreation
              </option>
              <option value="Creative Arts &amp; Entertainment Activities">
                Creative Arts &amp; Entertainment Activities
              </option>
              <option value="Accounting &amp; Auditing">
                Accounting &amp; Auditing
              </option>
              <option value="Real Estate">Real Estate</option>
              <option value="Restaurant, Cafe, Cloud Kitchen">
                Restaurant, Cafe, Cloud Kitchen
              </option>
              <option value="Investment Consultancy">
                Investment Consultancy
              </option>
              <option value="Management Consultancy">
                Management Consultancy
              </option>
              <option value="Retail Outlet/Shop">Retail Outlet/Shop</option>
              <option value="Holding Company">Holding Company</option>
              <option value="HR Consultancy">HR Consultancy</option>
              <option value="Project Management">Project Management</option>
              <option value="Cleaning Services">Cleaning Services</option>
              <option value="Maintenance">Maintenance</option>
              <option value="Interior Design Consultancy">
                Interior Design Consultancy
              </option>
              <option value="Technical Consultancy">
                Technical Consultancy
              </option>
              <option value="Software Publishing">Software Publishing</option>
              <option value="Logistics Consultancy">
                Logistics Consultancy
              </option>
              <option value="Sports Management">Sports Management</option>
              <option value="Photography">Photography</option>
              <option value="Aviation Consultancy">Aviation Consultancy</option>
              <option value="Hospitality Manangement">
                Hospitality Manangement
              </option>
              <option value="Legal Consultancy">Legal Consultancy</option>
              <option value="Videography">Videography</option>
              <option value="PR">PR</option>
              <option value="Photography">Photography</option>
              <option value="Delivery Service">Delivery Service</option>
              <option value="Media">Media</option>
            </select>
          </div>

          {/* Number of Visas Required */}
          <div style={{ textAlign: "left", marginBottom: "20px" }}>
            <label className={styles.label}>Number of Visas Required</label>
            <input
              name="visasRequired"
              value={formContent.visasRequired}
              onChange={handleChange}
              className={styles.input}
              type="number"
              min="1"
              placeholder="Enter number of visas required"
              required
            />
          </div>

          {/* Number of Shareholders */}
          <div style={{ textAlign: "left", marginBottom: "20px" }}>
            <label className={styles.label}>Number of Shareholders</label>
            <input
              name="shareholders"
              value={formContent.shareholders}
              onChange={handleChange}
              className={styles.input}
              type="number"
              min="1"
              placeholder="Enter number of shareholders"
              required
            />
          </div>

          {/* Require Office Space */}
          <div style={{ textAlign: "left", marginBottom: "20px" }}>
            <label className={styles.label}>Require Office Space?</label>
            <div>
              <input
                type="radio"
                id="office-yes"
                name="officeSpace"
                value="yes"
                checked={formContent.officeSpace === "yes"}
                onChange={handleChange}
                required
              />
              <label htmlFor="office-yes" className={styles.radioLabel}>
                Yes
              </label>
              <input
                type="radio"
                id="office-no"
                name="officeSpace"
                value="no"
                checked={formContent.officeSpace === "no"}
                onChange={handleChange}
              />
              <label htmlFor="office-no" className={styles.radioLabel}>
                No
              </label>
            </div>
          </div>

          {/* Next Button */}
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button
              type="button"
              className={`btn custom_btn ${styles.btn}`}
              onClick={nextStep}
            >
              Next
            </button>
          </div>
        </form>
      )}

      {step === 2 && (
        <form
          className={`${styles.formContainer} mb-4`}
          onSubmit={handleSubmit}
          data-aos="fade-up"
        >
          <div className="row">
            <div
              className="col-md-12 d-flex gap-4"
              style={{ marginBottom: "20px" }}
            >
              <div className={styles.formGroup}>
                <label className={styles.label}>First Name</label>
                <input
                  name="firstName"
                  value={formContent.firstName}
                  onChange={handleChange}
                  className={styles.input}
                  type="text"
                  placeholder="Enter your first name"
                  required
                />
              </div>

              <div className={styles.formGroup}>
                <label className={styles.label}>Last Name</label>
                <input
                  name="lastName"
                  value={formContent.lastName}
                  onChange={handleChange}
                  className={styles.input}
                  type="text"
                  placeholder="Enter your last name"
                  required
                />
              </div>
            </div>

            <div
              className="col-md-12 d-flex gap-4"
              style={{ marginBottom: "20px" }}
            >
              <div className={styles.formGroup}>
                <label className={styles.label}>Phone Number</label>
                <div className={styles.phoneWrapper}>
                  <select
                    id="country_code"
                    className={styles["mobile-country-code"]}
                    title="Country Code"
                    aria-hidden="true"
                    onChange={handleCountryChange}
                    value={selectedCountryCode}
                  >
                    <option value="">Select</option>
                    <option value="AE">UAE (+971)</option>
                    <option value="AF">Afghanistan (+93)</option>
                    <option value="AL">Albania (+355)</option>
                    <option value="DZ">Algeria (+213)</option>
                    <option value="AS">American Samoa (+1684)</option>
                    <option value="AD">Andorra (+376)</option>
                    <option value="AO">Angola (+244)</option>
                    <option value="AI">Anguilla (+1264)</option>
                    <option value="AG">Antigua and Barbuda (+1268)</option>
                    <option value="AR">Argentina (+54)</option>
                    <option value="AM">Armenia (+374)</option>
                    <option value="AW">Aruba (+297)</option>
                    <option value="AU">Australia (+61)</option>
                    <option value="AT">Austria (+43)</option>
                    <option value="AZ">Azerbaijan (+994)</option>
                    <option value="BS">Bahamas (+1242)</option>
                    <option value="BH">Bahrain (+973)</option>
                    <option value="BD">Bangladesh (+880)</option>
                    <option value="BB">Barbados (+1246)</option>
                    <option value="BY">Belarus (+375)</option>
                    <option value="BE">Belgium (+32)</option>
                    <option value="BZ">Belize (+501)</option>
                    <option value="BJ">Benin (+229)</option>
                    <option value="BM">Bermuda (+1441)</option>
                    <option value="BT">Bhutan (+975)</option>
                    <option value="BO">Bolivia (+591)</option>
                    <option value="BA">Bosnia and Herzegovina (+387)</option>
                    <option value="BW">Botswana (+267)</option>
                    <option value="BR">Brazil (+55)</option>
                    <option value="IO">
                      British Indian Ocean Territory (+246)
                    </option>
                    <option value="VG">British Virgin Islands (+1284)</option>
                    <option value="BN">Brunei (+673)</option>
                    <option value="BG">Bulgaria (+359)</option>
                    <option value="BF">Burkina Faso (+226)</option>
                    <option value="BI">Burundi (+257)</option>
                    <option value="KH">Cambodia (+855)</option>
                    <option value="CM">Cameroon (+237)</option>
                    <option value="CA">Canada (+1)</option>
                    <option value="KY">Cayman Islands (+1345)</option>
                    <option value="CF">Central African Republic (+236)</option>
                    <option value="TD">Chad (+235)</option>
                    <option value="CL">Chile (+56)</option>
                    <option value="CN">China (+86)</option>
                    <option value="CX">Christmas Island (+61)</option>
                    <option value="CC">Cocos Islands (+61)</option>
                    <option value="CO">Colombia (+57)</option>
                    <option value="KM">Comoros (+269)</option>
                    <option value="CG">Congo (+243)</option>
                    <option value="CD">
                      Congo (Democratic Republic) (+242)
                    </option>
                    <option value="CK">Cook Islands (+682)</option>
                    <option value="CR">Costa Rica (+506)</option>
                    <option value="CI">Cote d'Ivoire (+225)</option>
                    <option value="HR">Croatia (+385)</option>
                    <option value="CU">Cuba (+53)</option>
                    <option value="CY">Cyprus (+357)</option>
                    <option value="CZ">Czech Republic (+420)</option>
                    <option value="DK">Denmark (+45)</option>
                    <option value="DJ">Djibouti (+253)</option>
                    <option value="DM">Dominica (+1767)</option>
                    <option value="DO">Dominican Republic (+809)</option>
                    <option value="EC">Ecuador (+593)</option>
                    <option value="EG">Egypt (+20)</option>
                    <option value="SV">El Salvador (+503)</option>
                    <option value="GQ">Equatorial Guinea (+240)</option>
                    <option value="ER">Eritrea (+291)</option>
                    <option value="EE">Estonia (+372)</option>
                    <option value="ET">Ethiopia (+251)</option>
                    <option value="FK">Falkland Islands (+500)</option>
                    <option value="FO">Faroe Islands (+298)</option>
                    <option value="FJ">Fiji (+679)</option>
                    <option value="FI">Finland (+358)</option>
                    <option value="FR">France (+33)</option>
                    <option value="GF">French Guiana (+594)</option>
                    <option value="PF">French Polynesia (+689)</option>
                    <option value="GA">Gabon (+241)</option>
                    <option value="GM">Gambia (+220)</option>
                    <option value="GE">Georgia (+995)</option>
                    <option value="DE">Germany (+49)</option>
                    <option value="GH">Ghana (+233)</option>
                    <option value="GI">Gibraltar (+350)</option>
                    <option value="GR">Greece (+30)</option>
                    <option value="GL">Greenland (+299)</option>
                    <option value="GD">Grenada (+1473)</option>
                    <option value="GP">Guadeloupe (+590)</option>
                    <option value="GU">Guam (+1671)</option>
                    <option value="GT">Guatemala (+502)</option>
                    <option value="GG">Guernsey (+44)</option>
                    <option value="GN">Guinea (+224)</option>
                    <option value="GW">Guinea-Bissau (+245)</option>
                    <option value="GY">Guyana (+592)</option>
                    <option value="HT">Haiti (+509)</option>
                    <option value="HN">Honduras (+504)</option>
                    <option value="HK">Hong Kong (+852)</option>
                    <option value="HU">Hungary (+36)</option>
                    <option value="IS">Iceland (+354)</option>
                    <option value="IN">India (+91)</option>
                    <option value="ID">Indonesia (+62)</option>
                    <option value="IR">Iran (+98)</option>
                    <option value="IQ">Iraq (+964)</option>
                    <option value="IE">Ireland (+353)</option>
                    <option value="IM">Isle of Man (+44)</option>
                    <option value="IT">Italy (+39)</option>
                    <option value="JM">Jamaica (+1876)</option>
                    <option value="JP">Japan (+81)</option>
                    <option value="JE">Jersey (+44)</option>
                    <option value="JO">Jordan (+962)</option>
                  </select>
                  <input
                    name="phoneNumber"
                    value={formContent.phoneNumber}
                    onChange={handleChange}
                    className={styles.input}
                    type="tel"
                    pattern="[0-9]{10}"
                    placeholder="Enter your phone number"
                    required
                  />
                </div>
              </div>

              <div className={styles.formGroup}>
                <label className={styles.label}>Email</label>
                <input
                  name="email"
                  value={formContent.email}
                  onChange={handleChange}
                  className={styles.input}
                  type="email"
                  placeholder="Enter your email"
                  required
                />
              </div>
            </div>

            <div className="col-md-12" style={{ marginBottom: "20px" }}>
              <label className={styles.label}>Nationality</label>
              <input
                name="nationality"
                value={formContent.nationality}
                onChange={handleChange}
                className={styles.input}
                type="text"
                placeholder="Enter your nationality"
                required
              />
            </div>
          </div>

          <div className={styles.buttons}>
            <button
              type="button"
              className={`btn custom_btn ${styles.btn}`}
              onClick={prevStep}
            >
              Previous
            </button>
            <button type="submit" className={`btn custom_btn ${styles.btn}`}>
              {isLoading ? "Sending..." : "Submit"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Costcaliculation;
